import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Role } from "../../../../api/enums/Role";
import SideBar from "@/components/molecules/SideBar";

const Dashboard = () => {
  useRoleBasedRedirect(Role.ADMIN);

  return (
    <div className="flex flex-row bg-white">
      <SideBar />
    </div>
  );
};

export default Dashboard;
