import _ from "lodash";
import { useFormContext } from "react-hook-form";

const useChildForm = (fieldName?: string) => {
  // Get properties from useFormContext
  const formContext = useFormContext();
  const { formState } = formContext;

  // Create the `beforePath` based on the parentName
  const beforePath = fieldName ? `${fieldName}.` : "";
  const transformPath = (path: string) => `${beforePath}${path}`;

  // Filter errors based on parentName
  const errors = formState.errors;
  const filteredErrors = fieldName ? _.get(errors, fieldName) : errors;

  return {
    ...formContext,
    transformPath,
    filteredErrors,
  };
};

export default useChildForm;
