// import * as Sentry from "@sentry/react";
import { Toaster } from "@atoms/Toast/Toaster.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { StrictMode, useCallback, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "unfonts.css";
import { LoginResponse } from "./api/auth/dto/login.dto";
import { toast } from "./components/atoms/Toast/UseToast.tsx";
import { InfoToast } from "./components/molecules/ToastsTemplates.tsx";
import AdminDashBoard from "./components/pages/Admin/Dashboard/Dashboard.tsx";
import Login from "./components/pages/Admin/Login/Login.tsx";
import CandidateAccountCreation from "./components/pages/Candidate/AccountCreation/AccountCreation.tsx";
import CompanyAccountCreation from "./components/pages/Company/AccountCreation.tsx";
import CandidateDashboard from "./components/pages/Candidate/Dashboard/Dashboard.tsx";
import CompanyDashboard from "./components/pages/Company/Dashboard/Dashboard.tsx";
import Home from "./components/pages/ConnectionHome/ConnectionHome.tsx";
import ForgottenEmail from "./components/pages/ForgottenCredentials/ForgottenEmail.tsx";
import ForgottenPassword from "./components/pages/ForgottenCredentials/ForgottenPassword.tsx";
import PasswordChange from "./components/pages/ForgottenCredentials/PasswordChange.tsx";
import NotFound from "./components/pages/NotFound.tsx";
import SeedData from "./components/pages/SeedData.tsx";
import Welcome from "./components/pages/Welcome.tsx";
import { ENDPOINT } from "./constants/endpoints";
import { ROUTE } from "./constants/routes";
import { useAuthStore } from "./hooks/AuthStore";
import "./styles/index.css";

// FIXME: sentry triggers error on loading :
// types.js:3 Uncaught ReferenceError: Cannot access 'Rr' before initialization

// if (["production", "staging"].includes(import.meta.env.VITE_MODE)) {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.browserProfilingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     environment: import.meta.env.VITE_MODE,
//     tracesSampleRate: 1.0,
//     profilesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

const AppProviders = () => {
  const { login, logout, session } = useAuthStore();
  const [refreshing, setRefreshing] = useState(false);

  const [router] = useState(() => {
    const routes = [
      {
        path: ROUTE.admin.login,
        element: <Login />,
      },
      {
        path: ROUTE.connectionHome,
        element: <Home />,
      },
      {
        path: ROUTE.candidate.accountCreation,
        element: <CandidateAccountCreation />,
      },
      {
        path: ROUTE.candidate.dashboard,
        element: <CandidateDashboard />,
      },
      {
        path: ROUTE.candidate.dashboard,
        element: <CandidateDashboard />,
      },
      { path: ROUTE.forgotten.password.reset, element: <PasswordChange /> },
      { path: ROUTE.forgotten.email.ask, element: <ForgottenEmail /> },
      { path: ROUTE.forgotten.password.ask, element: <ForgottenPassword /> },
      { path: ROUTE.admin.dashboard, element: <AdminDashBoard /> },
      {
        path: ROUTE.company.dashboard,
        element: <CompanyDashboard />,
      },
      {
        path: ROUTE.company.accountCreation,
        element: <CompanyAccountCreation />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ];

    if (["staging", "development"].includes(import.meta.env.VITE_MODE)) {
      routes.push({
        path: ROUTE.home,
        element: <Welcome />,
      });

      routes.push({
        path: ROUTE.seedData,
        element: <SeedData />,
      });
    }

    return createBrowserRouter(routes);
  });

  const { navigate } = router;

  const disconnect = useCallback(() => {
    logout();
    toast({
      action: InfoToast({
        title: "Déconnexion : votre session a expiré.",
        description: "Reconnectez vous pour accéder à votre compte",
      }),
      duration: 1000 * 60 * 60,
    });
    navigate(ROUTE.connectionHome);
  }, [logout, navigate]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (refreshing) return;
          if (!session) return Promise.reject(error);
          if (session.refreshTokenExpirationTime < Date.now()) {
            disconnect();
            return Promise.reject(error);
          }

          setRefreshing(true);

          try {
            const { data: loginResponse } = await axios.post<LoginResponse>(
              ENDPOINT.auth.refresh
            );

            // update session
            login(session.email, loginResponse);

            setRefreshing(false);

            const newConfig = error.config as AxiosRequestConfig;
            if (!newConfig.headers) newConfig.headers = {};
            newConfig.headers[
              "Authorization"
            ] = `Bearer ${loginResponse.token}`;

            return axios.request(newConfig);
          } catch {
            disconnect();
            setRefreshing(false);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor); // Cleanup interceptor on unmount
    };
  }, [disconnect, login, refreshing, session]);

  const [queryClient] = useState(() => new QueryClient());

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </StrictMode>
  );
};

export default AppProviders;
