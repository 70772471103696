import { mandatoryString, optionnalString } from "@/constants/zodTypes";
import { z } from "zod";

const optionnalAddressSchema = z.object({
  country: optionnalString(),
  personnalAddress: optionnalString(),
  complement: optionnalString(),
  postalCode: optionnalString(),
  city: optionnalString(),
});

const mandatoryAddressSchema = z.object({
  country: mandatoryString(),
  personnalAddress: mandatoryString(),
  complement: optionnalString(),
  postalCode: mandatoryString(),
  city: mandatoryString(),
});

const addressDefaultValues: z.infer<typeof optionnalAddressSchema> = {
  country: "",
  personnalAddress: "",
  complement: "",
  postalCode: "",
  city: "",
};

export { addressDefaultValues, optionnalAddressSchema, mandatoryAddressSchema };
