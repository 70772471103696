import { ExamType } from "@/api/enums/ExamType";
import { Role } from "@/api/enums/Role";
import { Sex } from "@/api/enums/Sex";

const MOCK_PASSWORD = "Password123!";
// const MOCK_FUTURE_DATE = new Date(
//   new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
// );
// const MOCK_PAST_DATE = new Date(
//   new Date().getTime() - 10 * 365 * 24 * 60 * 60 * 1000
// );
const MOCK_FUTURE_ORDERED_DATES = Array.from(
  { length: 100 },
  (_, i) => new Date(new Date().getTime() + (i + 1) * 365 * 24 * 60 * 60 * 1000)
);
const MOCK_PAST_ORDERED_DATES = Array.from(
  { length: 100 },
  (_, i) =>
    new Date(new Date().getTime() - (100 - i) * 365 * 24 * 60 * 60 * 1000)
);

const companies = [
  {
    email: "company@example.com",
    key: MOCK_PASSWORD,
    role: Role.COMPANY,
    company: {
      create: {},
    },
  },
  {
    email: "telecom@random.com",
    key: MOCK_PASSWORD,
    role: Role.COMPANY,
    company: {
      create: {},
    },
  },
];

const candidates = [
  {
    email: "test@example.us",
    key: MOCK_PASSWORD,
    role: Role.CANDIDATE,
    candidate: {
      create: {
        sex: Sex.FEMALE,
        lastName: "Doe",
        firstName: "John",
        birthdate: new Date("1990-01-01"),
        birthCity: "New York",
        nationality: "Américaine",
        mobilePhone: "+15551234567",
        city: "New York",
        country: "Etats-Unis",
      },
    },
  },
  {
    email: "antoinet.galadrim@gmail.com",
    key: MOCK_PASSWORD,
    role: Role.CANDIDATE,
    candidate: {
      create: {
        sex: Sex.MALE,
        lastName: "Mochel",
        firstName: "Eric",
        birthdate: new Date("1990-01-01"),
        birthCity: "Nancy",
        nationality: "Française",
        mobilePhone: "+33612345678",
      },
    },
  },
];

const exams = [
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_FUTURE_ORDERED_DATES[0],
    formationCertificateEndDate: MOCK_FUTURE_ORDERED_DATES[1],
    date: MOCK_FUTURE_ORDERED_DATES[2],
    number: "I26",
    type: ExamType.INITIAL,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_PAST_ORDERED_DATES[1],
    formationCertificateEndDate: MOCK_FUTURE_ORDERED_DATES[1],
    date: MOCK_FUTURE_ORDERED_DATES[2],
    number: "I25",
    type: ExamType.INITIAL,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_PAST_ORDERED_DATES[1],
    formationCertificateEndDate: MOCK_PAST_ORDERED_DATES[2],
    date: MOCK_FUTURE_ORDERED_DATES[2],
    number: "I24",
    type: ExamType.INITIAL,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_PAST_ORDERED_DATES[1],
    formationCertificateEndDate: MOCK_PAST_ORDERED_DATES[2],
    date: MOCK_PAST_ORDERED_DATES[3],
    number: "I23",
    type: ExamType.INITIAL,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_FUTURE_ORDERED_DATES[0],
    formationCertificateEndDate: MOCK_FUTURE_ORDERED_DATES[1],
    date: MOCK_FUTURE_ORDERED_DATES[2],
    number: "R20",
    type: ExamType.RENEW,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_PAST_ORDERED_DATES[1],
    formationCertificateEndDate: MOCK_FUTURE_ORDERED_DATES[1],
    date: MOCK_FUTURE_ORDERED_DATES[2],
    number: "R19",
    type: ExamType.RENEW,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_PAST_ORDERED_DATES[1],
    formationCertificateEndDate: MOCK_PAST_ORDERED_DATES[2],
    date: MOCK_FUTURE_ORDERED_DATES[2],
    number: "R18",
    type: ExamType.RENEW,
  },
  {
    registeringStartDate: MOCK_PAST_ORDERED_DATES[0],
    registeringEndDate: MOCK_PAST_ORDERED_DATES[1],
    formationCertificateEndDate: MOCK_PAST_ORDERED_DATES[2],
    date: MOCK_PAST_ORDERED_DATES[3],
    number: "R17",
    type: ExamType.RENEW,
  },
];

export { companies, exams, candidates };
