import { Role } from "@/api/enums/Role";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import SideBar from "@/components/molecules/SideBar";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { TabsContent } from "@radix-ui/react-tabs";
import { Check, Info, UserSquare } from "lucide-react";
import Candidates from "./Candidates";
import Delegations from "./Delegations";
import Information from "./Information";

enum CompanyDashBoardPage {
  Delegations = "Delegations",
  Candidates = "Candidates",
  Information = "Information",
}

const Dashboard = () => {
  useRoleBasedRedirect(Role.COMPANY);

  return (
    <Tabs
      defaultValue={CompanyDashBoardPage.Delegations}
      className="flex flex-row w-full"
    >
      <SideBar
        collapsable={false}
        expandedChildren={
          <TabsList className="bg-transparent justify-start h-full items-start">
            <div className="flex flex-col px-4 text-gray-700 font-medium w-full">
              {[
                {
                  value: CompanyDashBoardPage.Delegations,
                  node: (
                    <>
                      <Check className="w-4 h-4" />
                      <span>Délégations</span>
                    </>
                  ),
                },
                {
                  value: CompanyDashBoardPage.Candidates,
                  node: (
                    <>
                      <UserSquare className="w-4 h-4" />
                      <span>Candidats</span>
                    </>
                  ),
                },
                {
                  value: CompanyDashBoardPage.Information,
                  node: (
                    <>
                      <Info className="w-4 h-4" />
                      <span>Informations entreprise</span>
                    </>
                  ),
                },
              ].map(({ value, node }) => (
                <TabsTrigger
                  value={value}
                  key={value}
                  className="justify-start data-[state=active]:bg-brand-100 data-[state=active]:text-brand-700 w-full"
                >
                  <div className="flex flex-row py-1.5 gap-2 items-center">
                    {node}
                  </div>
                </TabsTrigger>
              ))}
            </div>
          </TabsList>
        }
      />
      <TabsContent value={CompanyDashBoardPage.Delegations}>
        <Delegations />
      </TabsContent>
      <TabsContent value={CompanyDashBoardPage.Candidates}>
        <Candidates />
      </TabsContent>
      <TabsContent value={CompanyDashBoardPage.Information} className="w-full">
        <Information />
      </TabsContent>
    </Tabs>
  );
};

export default Dashboard;
