import { useDelegationsQuery } from "@/api/candidate/candidate";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { useGetAllExamsQuery as useExamsQuery } from "@/api/exam/exam";
import { Badge } from "@/components/atoms/Badge";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { ArrowDown, Search } from "lucide-react";

const Delegation = () => {
  const delegationsQuery = useDelegationsQuery();
  const examsQuery = useExamsQuery();

  if (delegationsQuery.isError || delegationsQuery.isLoading)
    return (
      <LoadingSpinner
        isLoading={delegationsQuery.isLoading}
        isError={delegationsQuery.isError}
        loadingMessage="Chargement de vos délégations..."
        errorMessage="Erreur lors du chargement de vos délégations."
      />
    );

  return (
    <div className="flex flex-col px-28 py-6 gap-6 flex-start self-stretch leading-tight">
      <div>
        <h1>Ma délégation</h1>
        <span className="text-gray-600 font-normal leading-none">
          La délégation permet la prise en charge du paiement de vos frais
          d'inscription, directement par votre entreprise (délégataire désigné).
          Le délégataire peut également gérer le dépôt des documents requis pour
          l'inscription (attestation(s) de formation) et a un accès à la note de
          débit, la convocation et l'attestation de présence. Vos autres données
          et documents restent personnels et confidentiels. Vous restez
          responsable du suivi de votre inscription et nous vous invitons à vous
          connecter régulièrement à votre espace pour consulter l'avancement de
          votre inscription.
        </span>
      </div>
      {delegationsQuery.data?.length ? (
        <Table className="text-gray-500 text-sm font-normal leading-6 border-gray-200 border">
          <TableHeader className="items-center border-gray-200 border bg-gray-50">
            <TableRow>
              {[
                "Examen",
                "Date de demande",
                "Délégataire",
                "Société",
                "Informations",
                "Statut",
              ].map((text, i) => (
                <TableHead key={i}>
                  <div className="flex flex-row items-center gap-3">
                    {text}
                    <ArrowDown className="w-4 h-4" />
                  </div>
                </TableHead>
              ))}
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {delegationsQuery.data.map(
              (
                {
                  examId,
                  status,
                  delegationProperties,
                  askedDate,
                  responseDate,
                },
                i
              ) => (
                <TableRow
                  className={cn("font-medium text-gray-900", {
                    "bg-white": i % 2 === 0,
                    "bg-gray-50": i % 2 !== 0,
                  })}
                  key={i}
                >
                  <TableCell>
                    {examsQuery.data
                      ? examsQuery.data.exams.find(({ id }) => id === examId)
                          ?.number
                      : "-"}
                  </TableCell>
                  <TableCell>{format(askedDate, "dd/MM/yyyy")}</TableCell>
                  <TableCell>
                    {delegationProperties.surname +
                      " " +
                      delegationProperties.name}
                  </TableCell>
                  <TableCell>{delegationProperties.companyName}</TableCell>
                  <TableCell className="text-gray-500">
                    {delegationProperties.email}
                    <br />
                    {delegationProperties.phoneNumber}
                  </TableCell>
                  <TableCell>
                    {status === DelegationStatus.ACCEPTED ? (
                      <Badge variant="green">
                        Acceptée le{" "}
                        {responseDate && format(responseDate, "dd/MM/yyyy")}
                      </Badge>
                    ) : status === DelegationStatus.REFUSED ? (
                      <Badge variant="red">
                        Refusée le{" "}
                        {responseDate && format(responseDate, "dd/MM/yyyy")}
                      </Badge>
                    ) : status === DelegationStatus.WAITING_ACCEPTATION ? (
                      <Badge variant="orange">En attente d'acceptation</Badge>
                    ) : status === DelegationStatus.WAITING_ACCOUNT_CREATION ? (
                      <Badge variant="sky">
                        En attente de création du compte
                      </Badge>
                    ) : (
                      "error"
                    )}
                  </TableCell>
                  <TableCell>
                    {/* This cell fixes background not on full width */}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      ) : (
        <div className="p-4 bg-white rounded-lg border border-gray-200">
          <div className="flex py-8 flex-col gap-4 self-stretch items-center rounded-lg bg-gray-100">
            <Search className="w-6 h-6 text-gray-400" />
            <span className="text-gray-800">
              Vous n'avez fait aucun demande de délégation
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Delegation;
