import { Button } from "@/components/atoms/Button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { ComboboxField } from "@/components/molecules/Combobox";
import DatePicker from "@/components/molecules/DatePicker";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import { PerimeterForm } from "@/components/molecules/PerimeterForm/PerimeterForm";
import { countries } from "@/constants/countries";
import useChildForm from "@/hooks/ChildForm";
import { Trash2 } from "lucide-react";

type CertificateFormProps = {
  fieldName?: string;
  remove?: (() => void) | null;
};

const rowClasses = "flex flex-row gap-2 w-full";

const CertificateForm: React.FC<CertificateFormProps> = ({
  fieldName,
  remove = null,
}) => {
  const { control, transformPath } = useChildForm(fieldName);

  return (
    <div className="flex flex-col items-start self-stretch w-full gap-2">
      <div className={`${rowClasses} justify-between`}>
        <FormField
          control={control}
          name={transformPath("country")}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel htmlFor={transformPath("country")}>
                Pays du certificat
              </FormLabel>
              <FormControl>
                <ComboboxField
                  options={countries.map((country) => ({
                    value: country.libelle,
                    label: country.libelle,
                  }))}
                  placeholder={field.value || "-"}
                  value={field.value}
                  onChange={field.onChange}
                  optionnal={true}
                  id={transformPath("country")}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className={rowClasses}>
        <FormField
          control={control}
          name={transformPath("number")}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Numéro de certificat</FormLabel>
              <FormControl>
                <Input placeholder={field.value || "-"} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={transformPath("expirationDate")}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Date de validité</FormLabel>
              <DatePicker
                value={field.value}
                onChange={field.onChange}
                restrictToToday={false}
                disabledDates={(date) => date < new Date()}
              />
            </FormItem>
          )}
        />
      </div>
      <div className={rowClasses}>
        <DropzoneMolecule fieldName={transformPath("file")}>
          <span>
            <span className="text-brand-700">Déposez</span> ou cliquez pour
            déposer votre certificat
          </span>
        </DropzoneMolecule>
      </div>
      <div className={rowClasses}>
        <div>Quel(s) mode(s) est/sont couvert(s) par votre certificat ?</div>
      </div>
      <PerimeterForm
        fieldName={transformPath("perimeter")}
        transportsTitleElement={<></>}
        merchandisesTitleElement={
          <div className="flex flex-col justify-start gap-2 self-stretch">
            Quelle(s) classe(s) de marchandises dangereuses est/sont couverte(s)
            par votre certificat ?
          </div>
        }
      />
      {remove !== null && (
        <div className={rowClasses}>
          <Button
            className="flex w-full px-3 py-2 justify-center items-center gap-1 self-stretch border rounded-lg bg-red-500 border-red-700 text-white font-semibold text-sm hover:bg-red-400"
            onClick={(e) => {
              e.preventDefault();
              remove();
            }}
          >
            Supprimer ce certificat
            <Trash2 className="w-5 h-5" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CertificateForm;
