"use client";

import { Button } from "@atoms/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@atoms/Command";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/Popover";
import { cn } from "@/lib/utils";
import { Check, ChevronDown } from "lucide-react";
import * as React from "react";
import { Option } from "react-day-picker";
import { useFormField } from "@atoms/Form";

type Option = {
  value: string;
  label: string;
};

interface ComboboxFieldProps {
  options: Option[];
  placeholder: string;
  value?: string; // To bind with react-hook-form
  onChange: (value: string) => void; // To bind with react-hook-form
  id?: string;
  optionnal?: boolean;
  disabled?: boolean;
  className?: string;
  contentWidth?: number;
  displaySearchBox?: boolean;
}

export const ComboboxField = ({
  options,
  placeholder,
  value,
  disabled = false,
  onChange,
  id,
  optionnal = false,
  className = "",
  contentWidth = -1,
  displaySearchBox = true,
}: ComboboxFieldProps) => {
  const [open, setOpen] = React.useState(false);
  const { error } = useFormField();

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full justify-between disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:opacity-100",
            error ? "border-red-500 border" : "border-gray-300",
            "max-w-full overflow-hidden text-ellipsis whitespace-nowrap",
            className
          )}
          id={id}
          disabled={disabled}
        >
          {" "}
          <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[calc(100%-2rem)]">
            {value
              ? options.find((option) => option.value === value)?.label
              : placeholder}
          </span>
          <ChevronDown className="ml-2 h-5 w-5 shrink-0" />
        </Button>
      </PopoverTrigger>
      {
        // PopoverContent same width as trigger :
        // https://github.com/shadcn-ui/ui/issues/1690#issuecomment-2016635419
      }
      <PopoverContent
        className={cn(
          contentWidth === -1
            ? "w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]"
            : `w-[${contentWidth}px]`,
          "p-0"
        )}
      >
        <Command>
          {displaySearchBox && (
            <CommandInput
              className="my-2 border-none focus:border-none"
              placeholder="Rechercher..."
            />
          )}
          <CommandList className="bg-blue-50">
            <CommandEmpty>Aucun résultat.</CommandEmpty>
            <CommandGroup>
              {
                // add null option if optionnal
                [
                  ...(optionnal ? [{ value: "", label: "-" }] : []),
                  ...options,
                ].map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={(currentValue) => {
                      onChange(currentValue === value ? "" : currentValue); // Notify react-hook-form of the change
                      setOpen(false);
                    }}
                    className="data-[selected=true]:bg-white"
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === option.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))
              }
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
