import { useState } from "react";
import ExamsPage from "./ExamsPageEnum";
import Home from "./Home";
import Register from "./Register/Register";

const Exams = () => {
  const [activePage, setActivePage] = useState(ExamsPage.Home);

  return activePage === ExamsPage.Home ? (
    <Home setActivePage={setActivePage} />
  ) : (
    <Register returnToHome={() => setActivePage(ExamsPage.Home)} />
  );
};

export default Exams;
