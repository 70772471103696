import { CircleAlert } from "lucide-react";

const ErrorDisplay = ({ msg }: { msg: string }) => {
  return (
    <div className="flex space-x-2 p-2 items-start gap-2 self-stretch rounded-lg border border-gray-300 bg-white">
      <CircleAlert className="text-red-500" />
      <div className="flex flex-col">
        <div className="font-bold">Erreur</div>
        <div className="text-gray-600">{msg}</div>
      </div>
    </div>
  );
};

export default ErrorDisplay;
