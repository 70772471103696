import { AlertCircle, Info } from "lucide-react";

const SuccessToast = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="flex items-start flex-col gap-3 ">
      <AlertCircle className="absolute right-5 top-5 h-6 w-6 text-green-500" />
      <div>
        {title && <h3 className="font-bold">{title}</h3>}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

const InfoToast = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="flex items-start flex-col gap-3 ">
      <Info className="absolute right-5 top-5 h-6 w-6 text-brand-600" />
      <div>
        {title && <h3 className="font-bold">{title}</h3>}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

export { SuccessToast, InfoToast };
