import { removeNullsDeep } from "@/api/app";
import { CertificateDto } from "@/api/dto/certificate.dto";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { ExamType } from "@/api/enums/ExamType";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { MerchandiseClass } from "@/api/enums/MerchandiseClass";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RenewingType } from "@/api/enums/RenewingType";
import { ExamOptionsDto } from "@/api/exam-registration/dto/exam-options.dto";
import { useGetAllExamsQuery } from "@/api/exam/exam";
import { useCandidateCertificatesQuery as useGetCandidateCertificatesQuery } from "@/api/candidate/candidate";
import { Button } from "@/components/atoms/Button";
import { GreenSwitchArrayValue } from "@/components/atoms/GreenSwitch";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { id, utc } from "@/constants/zodTypes";
import useChildForm from "@/hooks/ChildForm";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { RadioGroup, RadioGroupItem } from "@atoms/RadioGroup";
import { zodResolver } from "@hookform/resolvers/zod";
import { ComboboxField } from "@molecules/Combobox";
import {
  MerchandisesForm,
  PerimeterForm,
  TransportModesForm,
} from "@molecules/PerimeterForm/PerimeterForm";
import {
  merchandisesSchema,
  Perimeter,
  perimeterSchema,
  PerimeterUtil,
  transportsSchema,
} from "@molecules/PerimeterForm/PerimeterFormSchema";
import { format } from "date-fns";
import _ from "lodash";
import { GraduationCap, Info } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { z } from "zod";
import NextStepButton from "./NextStepButtons";

///////////////////
// sub-components
///////////////////

const RegistrationTypeRadio = () => {
  const { control } = useFormContext();

  return (
    <div>
      <h2>Type d'examen</h2>
      <FormField
        control={control}
        name="type"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <RadioGroup
                defaultValue={field.value}
                onValueChange={field.onChange}
                className="flex flex-row gap-16 w-full mt-1"
              >
                {[
                  {
                    value: RegistrationType.INITIAL,
                    msg: "Initial",
                  },
                  {
                    value: RegistrationType.RENEW,
                    msg: "Renouvellement d'un certificat",
                  },
                  {
                    value: RegistrationType.EXTENSION,
                    msg: "Extension d'un certificat valide",
                  },
                ].map(({ value, msg }) => (
                  <div className="flex items-center self-stretch" key={value}>
                    <RadioGroupItem
                      key={value}
                      value={value}
                      className="flex items-center justify-center gap-2"
                    />
                    <div>{msg}</div>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

interface ExamDateComboboxProps {
  fieldName: string;
}

const ExamDateCombobox = ({ fieldName }: ExamDateComboboxProps) => {
  const { control, watch } = useFormContext();

  const registrationType = watch("type");
  const getAllExamsQuery = useGetAllExamsQuery();

  if (getAllExamsQuery.isLoading || getAllExamsQuery.isError)
    return (
      <LoadingSpinner
        isLoading={getAllExamsQuery.isLoading}
        loadingMessage="Chargement des certificats..."
        isError={getAllExamsQuery.isError}
        errorMessage="Erreur lors du chargement des certificats"
      />
    );

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            <h2>Date de passage de l'examen</h2>
          </FormLabel>
          <FormControl>
            <ComboboxField
              options={
                getAllExamsQuery.isSuccess && getAllExamsQuery.data
                  ? getAllExamsQuery.data.exams
                      .filter(
                        (exam) =>
                          // still time to register
                          new Date(exam.registeringEndDate) >= new Date() &&
                          // coherent exam type
                          ((exam.type === ExamType.INITIAL &&
                            [
                              RegistrationType.EXTENSION,
                              RegistrationType.INITIAL,
                            ].includes(registrationType)) ||
                            (exam.type === ExamType.RENEW &&
                              registrationType === RegistrationType.RENEW))
                      )
                      .map((exam) => {
                        return {
                          value: exam.id.toString(),
                          label: `${format(
                            exam.date,
                            "dd/MM/yyyy"
                          )} (Date limite d'inscription le ${format(
                            exam.registeringEndDate,
                            "dd/MM/yyyy"
                          )} )`,
                        };
                      })
                  : []
              }
              placeholder={"-"}
              value={field.value.toString()}
              onChange={(str) => field.onChange(Number(str))}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

interface UTCComboboxProps {
  fieldName: string;
}

const UTCCombobox = ({ fieldName }: UTCComboboxProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            <h2>Fuseau horaire</h2>
          </FormLabel>
          <FormControl>
            <ComboboxField
              options={Array.from({ length: 27 }, (_, i) => i - 12).map(
                (utc) => {
                  return {
                    label: `UTC ${utc > 0 ? "+" : ""}${utc}`,
                    value: utc.toString(),
                  };
                }
              )}
              placeholder={"-"}
              value={field.value.toString()}
              onChange={(str) => field.onChange(Number(str))}
            />
          </FormControl>
          <FormMessage />
          <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white font-semibold shadow">
            <Info />
            Un aménagement d'horaire est possible pour les candidats localisé en
            heure UTC-3 et -4
          </div>
        </FormItem>
      )}
    />
  );
};

const WarningMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="font-bold">Attention</span>
        <span className="text-gray-600 font-normal">
          Votre choix de mode(s) de transport et de choix de la ou les classe(s)
          de marchandises dangereuses correspond à votre périmètre d'examen et
          de certificat. Nous vous invitons donc à bien vérifier l'adéquation de
          votre périmètre avec celui de votre ou vos formation(s), ainsi que
          celui de votre activité professionnelle.
        </span>
        <span className="text-brand-700 font-semibold">
          Si la date de clôture des inscriptions est dépassée, un changement ne
          sera plus possible.
        </span>
      </div>
    </div>
  );
};

const ExtensionMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <span className="font-bold">
        Vous êtes titulaire d'un certifcat en cours de validité et souhaitez son
        extension avec l'ajout d'un ou deux mode(s) de transport ou d'une{" "}
        <span className="text-brand-700">ou </span>
        plusieurs classe(s) de danger. Veuillez dans ce cas sélectionner le
        certificat associé.
      </span>
    </div>
  );
};

const RenewingTypeRadio = () => {
  const { control } = useFormContext();

  return (
    <div>
      <h2>Type de renouvellemet</h2>
      <FormField
        control={control}
        name="renewingProperties.renewingType"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <RadioGroup
                defaultValue={field.value}
                onValueChange={field.onChange}
                className="flex flex-row gap-16 w-full mt-1"
              >
                {[
                  {
                    value: RenewingType.SAME,
                    msg: "Même périmètre",
                  },
                  {
                    value: RenewingType.REDUCED,
                    msg: "Périmètre réduit",
                  },
                  {
                    value: RenewingType.FUSION,
                    msg: "Regroupement des périmètres",
                  },
                ].map(({ value, msg }) => (
                  <div className="flex items-center self-stretch" key={value}>
                    <RadioGroupItem
                      key={value}
                      value={value}
                      className="flex items-center justify-center gap-2"
                    />
                    <div>{msg}</div>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

const certificatesSchema = z
  .number()
  .array()
  .superRefine((ids, ctx) => {
    if (ids.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez choisir un certificat.",
      });
    }
  });

interface CertificatesSwitchesProps {
  fieldName: string;
  certificates: CertificateDto[];
  titleElement?: ReactNode;
  unique?: boolean;
}

const CertificatesSwitches = ({
  fieldName,
  certificates,
  titleElement,
  unique,
}: CertificatesSwitchesProps) => {
  const { filteredErrors } = useChildForm(fieldName);

  return (
    <div className="w-full">
      <div className={`flex flex-col w-full gap-2 flex-start self-stretch`}>
        {titleElement}
        {filteredErrors && (
          <p className="text-red-600 text-sm">
            {filteredErrors.message as string}
          </p>
        )}
        <FormMessage />
        {certificates.map((certificate) => (
          <GreenSwitchArrayValue
            key={certificate.file.fileKey}
            name={fieldName}
            value={(certificate.id as number).toString()}
            unique={unique}
            transform={(val) => Number(val)}
          >
            <div className="flex flex-row gap-2">
              <GraduationCap className="w-6 h-6 text-gray-900 stroke-1" />
              <div className="flex flex-row text-gray-700  gap-6 font-bold text-sm leading-6">
                <span>
                  N° de certificat:{" "}
                  <span className="font-medium">
                    {certificate.number.padStart(7, "")}
                  </span>
                </span>
                <span>
                  Fin de validité:{" "}
                  <span className="font-medium">
                    {format(certificate.expirationDate, "dd/MM/yyyy")}
                  </span>
                </span>
                <span>
                  Périmètre:{" "}
                  <span className="font-medium">
                    {PerimeterUtil.perimeterToCode(certificate.perimeter)}
                  </span>
                </span>
              </div>
            </div>
          </GreenSwitchArrayValue>
        ))}
      </div>
    </div>
  );
};

const ExtensionTypeButtons = () => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col w-full gap-2">
      <h2>Je souhaite ajouter...</h2>
      <FormField
        control={control}
        name="extensionProperties.extension.extensionType"
        render={({ field }) => (
          <FormItem className="w-full">
            <div className="flex flex-row w-full gap-2">
              <FormControl>
                <Button
                  className="flex w-full"
                  type="button"
                  variant={
                    field.value === ExtensionType.TRANSPORTS
                      ? "default"
                      : "white"
                  }
                  onClick={() => field.onChange(ExtensionType.TRANSPORTS)}
                >
                  {"Un ou des modes de transports"}
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  type="button"
                  className="flex w-full"
                  variant={
                    field.value === ExtensionType.MERCHANDISES
                      ? "default"
                      : "white"
                  }
                  onClick={() => field.onChange(ExtensionType.MERCHANDISES)}
                >
                  {"Une ou des classes dangereuses"}
                </Button>
              </FormControl>
            </div>
          </FormItem>
        )}
      />
    </div>
  );
};

///////////////////
// schema
///////////////////

const registerStepSchema = z.discriminatedUnion("type", [
  // initial
  z.object({
    type: z.literal(RegistrationType.INITIAL),
    initialExamId: id("Veuillez choisir un examen."),
    initialProperties: z.object({
      utc: utc(),
      perimeter: perimeterSchema,
    }),
  }),
  // extension
  z.object({
    type: z.literal(RegistrationType.EXTENSION),
    extensionExamId: id("Veuillez choisir un examen."),
    extensionProperties: z.object({
      utc: utc(),
      certificateId: certificatesSchema, // one certificate only must be chosen but this make things easier with components
      extension: z.discriminatedUnion("extensionType", [
        // extend transports
        z.object({
          extensionType: z.literal(ExtensionType.TRANSPORTS),
          extendedTransportModes: transportsSchema,
        }),
        // extend merchandises
        z.object({
          extensionType: z.literal(ExtensionType.MERCHANDISES),
          extendedMerchandiseClasses: merchandisesSchema,
        }),
      ]),
    }),
  }),
  // renewal
  z.object({
    type: z.literal(RegistrationType.RENEW),
    renewingExamId: id("Veuillez choisir un examen."),
    renewingProperties: z
      .object({
        renewingCertificatesIDs: certificatesSchema,
        perimeter: perimeterSchema,
        renewingType: z.nativeEnum(RenewingType),
      })
      .superRefine(({ renewingCertificatesIDs, renewingType }, ctx) => {
        if (renewingCertificatesIDs.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir au moins 1 certificat.",
            path: ["renewingCertificatesIDs"],
          });

          return;
        }

        if (
          [RenewingType.REDUCED, RenewingType.SAME].includes(renewingType) &&
          renewingCertificatesIDs.length > 1
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir 1 certificat maximum.",
            path: ["renewingCertificatesIDs"],
          });
        }

        if (
          renewingType === RenewingType.FUSION &&
          renewingCertificatesIDs.length < 2
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir au moins 2 certificats.",
            path: ["renewingCertificatesIDs"],
          });
        }
      }),
  }),
]);

///////////////////
// main component
///////////////////

interface RegisterStepProps {
  onNextStep: (goNext: boolean) => void;
  registrationDraft: ExamOptionsDto;
  changeDraft: (newOptions: ExamOptionsDto) => void;
}

const RegisterStep = ({
  onNextStep,
  registrationDraft,
  changeDraft,
}: RegisterStepProps) => {
  ////////////
  // api calls
  ////////////

  const certificatesQuery = useGetCandidateCertificatesQuery();

  ////////////
  // form
  ////////////

  const form = useForm<z.infer<typeof registerStepSchema>>({
    resolver: zodResolver(registerStepSchema),
    defaultValues: _.merge(
      {
        type: RegistrationType.INITIAL,
        initialExamId: -1,
        renewingExamId: -1,
        extensionExamId: -1,
        initialProperties: {
          utc: 1,
          perimeter: PerimeterUtil.emptyPerimeter(),
        },
        renewingProperties: {
          type: RegistrationType.RENEW,
          perimeter: PerimeterUtil.emptyPerimeter(),
          renewingCertificatesIDs: [],
          renewingType: RenewingType.SAME,
        },
        extensionProperties: {
          type: RegistrationType.EXTENSION,
          utc: 1,
          certificateId: [],
          extension: {
            extensionType: ExtensionType.MERCHANDISES,
            extendedMerchandiseClasses: [],
            extendedTransportModes: [],
          },
        },
      },
      removeNullsDeep({
        type: registrationDraft.type,
        initialExamId:
          registrationDraft.type === RegistrationType.INITIAL
            ? registrationDraft.examId
            : -1,
        renewingExamId:
          registrationDraft.type === RegistrationType.RENEW
            ? registrationDraft.examId
            : -1,
        extensionExamId:
          registrationDraft.type === RegistrationType.EXTENSION
            ? registrationDraft.examId
            : -1,
        initialProperties: registrationDraft.initialProperties,
        renewingProperties: registrationDraft.renewingProperties,
        extensionProperties: {
          utc: registrationDraft.extensionProperties?.utc,
          certificateId: [registrationDraft.extensionProperties?.certificateId],
          extension: {
            extensionType: registrationDraft.extensionProperties?.extensionType,
            extendedMerchandiseClasses:
              registrationDraft.extensionProperties?.extendedMerchandiseClasses,
            extendedTransportModes:
              registrationDraft.extensionProperties?.extendedTransportModes,
          },
        },
      })
    ),
  });

  const onSubmit = (
    values: z.infer<typeof registerStepSchema>,
    goNext: boolean
  ) => {
    const { data, error, success } = registerStepSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    changeDraft({
      ...(data.type === RegistrationType.INITIAL && {
        examId: data.initialExamId,
        initialProperties: data.initialProperties,
      }),
      ...(data.type === RegistrationType.EXTENSION && {
        examId: data.extensionExamId,
        extensionProperties: {
          extendedMerchandiseClasses: [],
          extendedTransportModes: [],
          utc: data.extensionProperties.utc,
          certificateId: data.extensionProperties.certificateId[0],
          ...data.extensionProperties.extension,
        },
      }),
      ...(data.type === RegistrationType.RENEW && {
        examId: data.renewingExamId,
        renewingProperties: data.renewingProperties,
      }),
      type: data.type,
    });
    onNextStep(goNext);
  };

  const { setValue, watch } = form;
  const registrationType = watch("type");

  /// renewal update mechanism

  const renewingType = watch("renewingProperties.renewingType");
  const renewingCertificatesIDs = watch(
    "renewingProperties.renewingCertificatesIDs"
  );
  const [renewalAllowedPerimeter, setRenewalAllowedPerimeter] =
    useState<Perimeter>({ merchandises: [], transportModes: [] });

  // auto-select allowed fields
  useEffect(() => {
    const newAllowedPerimeter = PerimeterUtil.perimetersUnion(
      ...(certificatesQuery.data
        ?.filter(({ id }) => renewingCertificatesIDs.includes(id as number))
        .map(({ perimeter }) => perimeter) || [])
    );

    if ([RenewingType.SAME, RenewingType.FUSION].includes(renewingType)) {
      setValue("renewingProperties.perimeter", newAllowedPerimeter);
    } else {
      const restrictedPerimeter = PerimeterUtil.perimeterRestrict(
        watch("renewingProperties.perimeter"),
        newAllowedPerimeter
      );
      setValue("renewingProperties.perimeter", restrictedPerimeter);
    }

    setRenewalAllowedPerimeter(newAllowedPerimeter);
  }, [
    renewingType,
    renewingCertificatesIDs,
    setValue,
    watch,
    certificatesQuery.data,
  ]);

  // avoid multiple selections if forbidden
  useEffect(() => {
    if ([RenewingType.SAME, RenewingType.REDUCED].includes(renewingType)) {
      setValue(
        "renewingProperties.renewingCertificatesIDs",
        watch("renewingProperties.renewingCertificatesIDs").slice(0, 1)
      );
    }
  }, [renewingType, setValue, watch]);

  /// extension update mechanism

  const extensionType = watch("extensionProperties.extension.extensionType");
  const extensionCertificateID = watch("extensionProperties.certificateId");
  const [extensionMinimumPerimeter, setExtensionMinimumPerimeter] =
    useState<Perimeter>(PerimeterUtil.fullPerimeter());

  useEffect(() => {
    const newMinimumPerimeter =
      certificatesQuery.data?.find(({ id }) =>
        extensionCertificateID.includes(id as number)
      )?.perimeter || PerimeterUtil.fullPerimeter();

    if (extensionType === ExtensionType.MERCHANDISES)
      setValue(
        "extensionProperties.extension.extendedMerchandiseClasses",
        watch(
          "extensionProperties.extension.extendedMerchandiseClasses"
        ).filter((val) => !newMinimumPerimeter.merchandises.includes(val))
      );
    else
      setValue(
        "extensionProperties.extension.extendedTransportModes",
        watch("extensionProperties.extension.extendedTransportModes").filter(
          (val) => !newMinimumPerimeter.transportModes.includes(val)
        )
      );

    setExtensionMinimumPerimeter(newMinimumPerimeter);
  }, [
    extensionType,
    extensionCertificateID,
    setValue,
    watch,
    certificatesQuery.data,
  ]);

  const validCertificates =
    certificatesQuery.data?.filter(
      ({ expirationDate, status }) =>
        new Date(expirationDate) >= new Date() &&
        (status as CertificateStatus) === CertificateStatus.VALIDATED
    ) ?? [];

  return (
    <div className="flex flex-col items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Inscription à l'examen</h1>
            <RegistrationTypeRadio />
            {registrationType === RegistrationType.INITIAL && (
              <>
                <ExamDateCombobox fieldName="initialExamId" />
                <UTCCombobox fieldName="initialProperties.utc" />
                <PerimeterForm
                  fieldName="initialProperties.perimeter"
                  hiddenPerimeter={{
                    merchandises: [MerchandiseClass.HYDROCARBONS],
                    transportModes: [],
                  }} // hydrocarbons not proposed anymore in initial
                />
              </>
            )}
            {registrationType === RegistrationType.RENEW && (
              <>
                <ExamDateCombobox fieldName="renewingExamId" />
                <RenewingTypeRadio />
                {certificatesQuery.isLoading ? (
                  <div>Chargement des certificats...</div>
                ) : certificatesQuery.isError ? (
                  <div>Erreur lors du chargement des certificats.</div>
                ) : (
                  <>
                    <CertificatesSwitches
                      certificates={validCertificates}
                      unique={[
                        RenewingType.SAME,
                        RenewingType.REDUCED,
                      ].includes(renewingType)}
                      fieldName="renewingProperties.renewingCertificatesIDs"
                      titleElement={<h2>Vos certificat(s) à renouveler</h2>}
                    />
                    <PerimeterForm
                      fieldName="renewingProperties.perimeter"
                      disabledPerimeter={
                        [RenewingType.SAME, RenewingType.FUSION].includes(
                          renewingType
                        )
                          ? PerimeterUtil.fullPerimeter()
                          : PerimeterUtil.emptyPerimeter()
                      }
                      displayedPerimeter={renewalAllowedPerimeter}
                      uncheckedAreRed={renewingType === RenewingType.REDUCED}
                    />
                  </>
                )}
              </>
            )}
            {registrationType === RegistrationType.EXTENSION && (
              <>
                <ExamDateCombobox fieldName="extensionExamId" />
                <UTCCombobox fieldName="extensionProperties.utc" />
                <CertificatesSwitches
                  certificates={validCertificates}
                  unique={true}
                  fieldName="extensionProperties.certificateId"
                  titleElement={
                    <>
                      <h2>Extension de certificat</h2>
                      <ExtensionMessage />
                    </>
                  }
                />
                <ExtensionTypeButtons />
                {extensionType === ExtensionType.MERCHANDISES && (
                  <MerchandisesForm
                    fieldName="extensionProperties.extension.extendedMerchandiseClasses"
                    hiddenMerchandises={[
                      ...extensionMinimumPerimeter.merchandises,
                      MerchandiseClass.HYDROCARBONS,
                    ]}
                  />
                )}
                {extensionType === ExtensionType.TRANSPORTS && (
                  <TransportModesForm
                    fieldName="extensionProperties.extension.extendedTransportModes"
                    hiddenTransportModes={
                      extensionMinimumPerimeter.transportModes
                    }
                  />
                )}
              </>
            )}
            <WarningMessage />
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data, true))}
            onPrevious={() => onNextStep(false)}
          />
        </form>
      </Form>
    </div>
  );
};

export default RegisterStep;
