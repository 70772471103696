const ENDPOINT = {
  helloWorld: "/",
  debugSentry: "/debug-sentry",
  auth: {
    login: "/auth/login",
    refresh: "/auth/refresh",
    logout: "/auth/logout",
    role: "/auth/role",
    changeEmail: "/auth/change-email",
    candidate: {
      register: "/auth/register/candidate",
      askUploadLinks: "/auth/register/candidate/ask-upload-links",
    },
    company: {
      register: "/auth/register/company",
      prefilledInfo: "/auth/register/company/get-prefilled-info",
    },
    password: {
      askChange: "/auth/password/ask-change",
      change: "/auth/password/change",
    },
  },
  candidate: {
    info: "/candidate",
    changeInfo: "/candidate/change-info",
    certificates: "/candidate/certificates",
    certificateUpload: "/candidate/certificate-upload",
    certificateAskUpload: "/candidate/certificate-ask-upload",
    delegations: "/candidate/delegations",
  },
  company: {
    info: "/company",
    changeInfo: "/company/change-info",
  },
  examRegistration: {
    create: "/exam-registration/",
    setOptions: "/exam-registration/set-options",
    getOptions: "/exam-registration/get-options",
    confirm: "/exam-registration/confirm",
    askUploadReducedPriceJustification:
      "/exam-registration/ask-upload-reduced-price-justification",
    askUploadFormationCertificates:
      "/exam-registration/ask-upload-certifications",
  },
  exam: {
    getAll: "/exam/",
  },
  formationInstitutes: {
    getAll: "/formation-institutes",
  },
};

export { ENDPOINT };
