"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Button } from "@atoms/Button";
import { Calendar } from "@atoms/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/Popover";
import { cn } from "@/lib/utils";
import { FormControl, useFormField } from "@atoms/Form";

interface DatePickerProps {
  value: Date;
  onChange: (date: Date) => void;
  restrictToToday?: boolean;
  disabled?: boolean;
  disabledDates?: (date: Date) => boolean;
}

const DatePicker = ({
  value,
  onChange,
  restrictToToday = false,
  disabled = false,
  disabledDates,
}: DatePickerProps) => {
  const { error } = useFormField();

  return (
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant={"outline"}
            className={cn(
              "w-full border-gray-300 justify-start text-left font-normal disabled:opacity-100 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50",
              !value && "text-muted-foreground",
              error && "border-red-500 border"
            )}
            disabled={disabled}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value ? format(value, "dd/MM/yyyy") : <span>DD/MM/YYYY</span>}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          required={true}
          mode="single"
          selected={value}
          onSelect={onChange}
          disabled={(date) =>
            restrictToToday
              ? date > new Date() || date < new Date("1900-01-01")
              : disabledDates
              ? disabledDates(date)
              : false
          }
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
