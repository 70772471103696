import { MerchandiseClass } from "@/api/enums/MerchandiseClass";
import { TransportMode } from "@/api/enums/TransportMode";
import { GreenSwitchArrayValue } from "@/components/atoms/GreenSwitch";
import useChildForm from "@/hooks/ChildForm";
import { FormMessage } from "@atoms/Form";
import {
  Bomb,
  Fuel,
  Radiation,
  Ship,
  TestTube,
  TrainFront,
  Truck,
  Wind,
} from "lucide-react";
import { ReactNode } from "react";
import { Perimeter } from "./PerimeterFormSchema";

const defaultTransportsTitle = <h2>Choix du ou des mode(s) de transport</h2>;
const defaultMerchandisesTitle = (
  <h2>Choix de la ou les classe(s) de marchandises dangereuses</h2>
);

interface TransportModesFormProps {
  fieldName?: string;
  titleElement?: ReactNode;
  displayedTransportModes?: TransportMode[];
  hiddenTransportModes?: TransportMode[];
  disabledTransportModes?: TransportMode[];
  uncheckedAreRed?: boolean;
}

const TransportModesForm = ({
  fieldName,
  titleElement = defaultTransportsTitle,
  displayedTransportModes,
  hiddenTransportModes = [],
  disabledTransportModes = [],
  uncheckedAreRed,
}: TransportModesFormProps) => {
  const { filteredErrors } = useChildForm(fieldName);

  if (displayedTransportModes !== undefined) {
    hiddenTransportModes = Object.values(TransportMode).filter(
      (val) => !displayedTransportModes.includes(val)
    );
  }

  const choices = [
    {
      value: TransportMode.ROAD,
      children: (
        <>
          <Truck className="w-6 h-6" />
          <span>Route</span>
        </>
      ),
    },
    {
      value: TransportMode.TRAIN,
      children: (
        <>
          <TrainFront className="w-6 h-6" />
          <span>Ferroviaire</span>
        </>
      ),
    },
    {
      value: TransportMode.BOAT,
      children: (
        <>
          <Ship className="w-6 h-6" />
          <span>Navigable</span>
        </>
      ),
    },
  ];

  const children = choices
    .filter(({ value }) => !hiddenTransportModes.includes(value))
    .map(({ value, children }) => (
      <GreenSwitchArrayValue
        key={value}
        name={fieldName}
        value={value}
        disabled={disabledTransportModes.includes(value)}
        uncheckedAreRed={uncheckedAreRed}
      >
        {children}
      </GreenSwitchArrayValue>
    ));

  return (
    <div className="flex flex-col gap-2 w-full">
      {titleElement}
      {filteredErrors && (
        <p className="text-red-600 text-sm">
          {filteredErrors.message as string}
        </p>
      )}
      <FormMessage />
      <div className="flex flex-row gap-2 w-full">
        {children.length > 0 ? children : "-"}
      </div>
    </div>
  );
};

interface MerchandisesFormProps {
  fieldName?: string;
  titleElement?: ReactNode;
  displayedMerchandises?: MerchandiseClass[];
  hiddenMerchandises?: MerchandiseClass[];
  disabledMerchandises?: MerchandiseClass[];
  uncheckedAreRed?: boolean;
}

const MerchandisesForm = ({
  fieldName,
  titleElement = defaultMerchandisesTitle,
  displayedMerchandises,
  disabledMerchandises = [],
  hiddenMerchandises = [],
  uncheckedAreRed,
}: MerchandisesFormProps) => {
  const { filteredErrors } = useChildForm(fieldName);

  if (displayedMerchandises !== undefined) {
    hiddenMerchandises = Object.values(MerchandiseClass).filter(
      (val) => !displayedMerchandises.includes(val)
    );
  }

  const choices = [
    {
      value: MerchandiseClass.EXPLOSIVES,
      children: (
        <>
          <Bomb className="w-6 h-6" />
          <span>Classe 1 : Matières explosives</span>
        </>
      ),
    },
    {
      value: MerchandiseClass.GAS,
      children: (
        <>
          <Wind className="w-6 h-6" />
          <span>Classe 2 : Gaz</span>
        </>
      ),
    },
    {
      value: MerchandiseClass.RADIOACTIVE,
      children: (
        <>
          <Radiation className="w-6 h-6" />
          <span>Classe 7 : Matières radioactives</span>
        </>
      ),
    },
    {
      value: MerchandiseClass.CHEMICAL,
      children: (
        <>
          <TestTube className="w-6 h-6" />
          <span>Classes 3 à 9 sauf 7 : Chimie</span>
        </>
      ),
    },
    {
      value: MerchandiseClass.HYDROCARBONS,
      children: (
        <>
          <Fuel className="w-6 h-6" />
          <span>
            Hydrocarbures : N°ONU 1202, 1203, 1223, 3475 et le carburant
            aviation classé sous les N°ONU 1268 ou 1863
          </span>
        </>
      ),
    },
  ];

  const children = choices
    .filter(({ value }) => !hiddenMerchandises.includes(value))
    .map(({ value, children }) => (
      <GreenSwitchArrayValue
        key={value}
        name={fieldName}
        value={value}
        disabled={disabledMerchandises.includes(value)}
        uncheckedAreRed={uncheckedAreRed}
      >
        {children}
      </GreenSwitchArrayValue>
    ));

  return (
    <div className="flex flex-col w-full gap-2">
      {titleElement}
      {filteredErrors && (
        <p className="text-red-600 text-sm">
          {filteredErrors.message as string}
        </p>
      )}
      <FormMessage />
      {children.length > 0 ? children : "-"}
    </div>
  );
};

interface PerimeterFormProps {
  fieldName: string;

  transportsTitleElement?: ReactNode;
  merchandisesTitleElement?: ReactNode;

  displayedPerimeter?: Perimeter;
  hiddenPerimeter?: Perimeter;
  disabledPerimeter?: Perimeter;

  uncheckedAreRed?: boolean;
}

const PerimeterForm = ({
  fieldName,
  transportsTitleElement,
  merchandisesTitleElement,
  displayedPerimeter,
  hiddenPerimeter,
  disabledPerimeter,
  uncheckedAreRed,
}: PerimeterFormProps) => {
  return (
    <>
      <TransportModesForm
        fieldName={`${fieldName}.transportModes`}
        titleElement={transportsTitleElement}
        uncheckedAreRed={uncheckedAreRed}
        disabledTransportModes={disabledPerimeter?.transportModes}
        hiddenTransportModes={hiddenPerimeter?.transportModes}
        displayedTransportModes={displayedPerimeter?.transportModes}
      />
      <MerchandisesForm
        fieldName={`${fieldName}.merchandises`}
        titleElement={merchandisesTitleElement}
        uncheckedAreRed={uncheckedAreRed}
        disabledMerchandises={disabledPerimeter?.merchandises}
        hiddenMerchandises={hiddenPerimeter?.merchandises}
        displayedMerchandises={displayedPerimeter?.merchandises}
      />
    </>
  );
};

export { MerchandisesForm, PerimeterForm, TransportModesForm };
