import { ENDPOINT } from "@/constants/endpoints";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { GetAllDto } from "./dto/get-all.dto";
import { keyFactory } from "../keyFactory";

// get all existing exams

const getAllExams = async () => {
  return axios
    .get<GetAllDto | null>(ENDPOINT.exam.getAll)
    .then((res) => res.data);
};

export const useGetAllExamsQuery = (
  props?: UseQueryOptions<GetAllDto | null, AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.examAll(),
    queryFn: getAllExams,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};
