import { Edit } from "lucide-react";
import { ReactNode } from "react";
import { Button } from "../atoms/Button";

interface ModifiableFormProps {
  children?: ReactNode;
  setModifiable: (b: boolean) => void;
  onCancel: () => void;
  modifyText: string;
  saveText: string;
  isModifiable: boolean;
}

const ModifiableForm = ({
  children,
  modifyText,
  saveText,
  onCancel,
  setModifiable,
  isModifiable,
}: ModifiableFormProps) => {
  return (
    <div className="flex flex-col flex-start p-6 self-stretch gap-4 rounded-lg items-end bg-white">
      <Button
        type="button"
        onClick={() => {
          if (isModifiable) onCancel();
          setModifiable(!isModifiable);
        }}
        variant="outlineBlue"
        className="absolute"
      >
        {!isModifiable && <Edit className="w-4 h-4" />}
        {!isModifiable ? modifyText : "Annuler"}
      </Button>
      {children}
      {isModifiable && (
        <Button type="submit" variant="default" className="w-fit">
          {saveText}
        </Button>
      )}
    </div>
  );
};

export default ModifiableForm;
