import { Checkbox } from "@/components/atoms/CheckBox";
import { cn } from "@/lib/utils";
import { ReactNode, useEffect, useRef, useState } from "react";
import NextStepButton from "../NextStepButtons";
import CGU from "./CGU";
import CGV from "./CGV";

interface MustBeScrolledBoxProps {
  checked: boolean;
  setChecked: (b: boolean) => void;
  title: string;
  checkedText: string;
  children: ReactNode;
}

const MustBeScrolledBox = ({
  checked,
  setChecked,
  checkedText,
  children,
  title,
}: MustBeScrolledBoxProps) => {
  const [canCheck, setCanCheck] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  // Check if the user scrolled to the bottom
  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setCanCheck(true); // Enable the checkbox when scrolled to the bottom
      }
    }
  };

  useEffect(() => {
    const contentEl = contentRef.current;
    if (contentEl) {
      contentEl.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (contentEl) {
        contentEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3 mt-2 w-full">
        <span className="text-gray-700 text-sm font-medium">{title}</span>
        <div
          ref={contentRef}
          className="shadow-sm border border-gray-300 bg-white rounded-sm p-4 max-h-96 overflow-auto"
        >
          {children}
        </div>
      </div>
      <div
        className={cn("flex flex-row items-center gap-2", {
          "text-gray-700": checked,
        })}
      >
        <Checkbox
          disabled={!canCheck}
          checked={checked}
          onCheckedChange={(checked) => setChecked(!!checked)}
          className={cn("rounded-sm", {
            "bg-gray-50 border border-gray-300": !checked,
          })}
        />
        {checkedText}
      </div>
    </>
  );
};

interface AcknowledgmentProps {
  onNextStep: (goNext: boolean) => void;
}

const Acknowledgement = ({ onNextStep }: AcknowledgmentProps) => {
  const [CGUChecked, setCGUChecked] = useState(false);
  const [CGVChecked, setCGVChecked] = useState(false);

  return (
    <div className="w-full flex flex-col gap-8 items-start max-w-4xl">
      <div className="flex p-4 flex-col items-start gap-4 self-stretch rounded-md border-gray-200 bg-white border w-full text-gray-500 text-sm font-medium">
        <h1>Acceptation des CGU et CGV</h1>
        <MustBeScrolledBox
          checked={CGUChecked}
          title="Veuillez lire dans leur ensemble les conditions générales d'utilisation"
          checkedText="J'ai lu et j'accepte les CGU*"
          setChecked={setCGUChecked}
        >
          <CGU />
        </MustBeScrolledBox>
        <MustBeScrolledBox
          checked={CGVChecked}
          title="Veuillez lire dans leur ensemble les conditions générales de ventes"
          checkedText="J'ai lu et j'accepte les CGV*"
          setChecked={setCGVChecked}
        >
          <CGV />
        </MustBeScrolledBox>
      </div>
      <NextStepButton
        onNext={() => onNextStep(true)}
        onPrevious={() => onNextStep(false)}
        nextDisabled={!(CGUChecked && CGVChecked)}
        nextButtonText="Valider ma pré-inscription"
      />
    </div>
  );
};

export default Acknowledgement;
