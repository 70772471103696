import { z } from "zod";

const mandatoryString = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .max(50, { message: "50 caractères maximum" })
    .trim();

const optionnalString = () => mandatoryString().or(z.literal("")).default("");

const email = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .email({ message: "Veuillez entrer une adresse email valide" })
    .trim();

const password = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .min(8, { message: "Le mot de passe doit contenir au moins 8 caractères" })
    .max(50, {
      message: "Le mot de passe doit contenir au plus 100 caractères",
    })
    .trim()
    .refine(
      (password) => {
        // backend DTO applies the same rules
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return hasUppercase && hasLowercase && hasNumber && hasSymbol;
      },
      {
        message:
          'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un symbole (!@#$%^&*(),.?":{}|<></>)',
      }
    );

const optionalPhone = () => z.string().optional().default("");

const mandatoryPhone = () => mandatoryString();

const DEFAULT_ACCEPTED_FILE_TYPES = ["application/pdf"];
const DEFAULT_MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const emptyfile = () => new File([""], "");

const file = (
  accepted_types: string[] = DEFAULT_ACCEPTED_FILE_TYPES,
  max_size: number = DEFAULT_MAX_FILE_SIZE
) =>
  z
    .custom<File>()
    .refine((file: File) => file.size > 0, {
      message: "Fichier manquant",
    })
    .refine((file: File) => accepted_types.includes(file.type), {
      message: `Format invalide : seulement les formats ${accepted_types
        .map((type) => type.split("/")[1])
        .join(", ")} sont acceptés`,
    })
    .refine((file: File) => file.size <= max_size, {
      message: `Fichier trop grand. Taille maximale autorisée : ${
        max_size / (1024 * 1024)
      } MB`,
    });

const optionnalFile = (
  accepted_types: string[] = DEFAULT_ACCEPTED_FILE_TYPES,
  max_size: number = DEFAULT_MAX_FILE_SIZE
) =>
  z
    .custom<File>()
    .optional()
    .refine((file: File | undefined) => !file || file.size > 0, {
      message: "Fichier manquant",
    })
    .refine(
      (file: File | undefined) => !file || accepted_types.includes(file.type),
      {
        message: `Format invalide : seulement les formats ${accepted_types
          .map((type) => type.split("/")[1])
          .join(", ")} sont acceptés`,
      }
    )
    .refine((file: File | undefined) => !file || file.size <= max_size, {
      message: `Fichier trop grand. Taille maximale autorisée : ${
        max_size / (1024 * 1024)
      } MB`,
    })
    .default(emptyfile());

const date = (minDate: Date | null = null, maxDate: Date | null = null) =>
  z
    .union([z.string(), z.date()])
    .transform((value) => {
      if (value instanceof Date) {
        return value;
      }

      const date = new Date(value);
      if (isNaN(date.getTime())) {
        throw new Error("Format invalide");
      }
      return date;
    })
    .refine((date) => !minDate || date >= minDate, {
      message: `Veuillez choisir une date postérieure au ${(
        minDate as Date
      ).toLocaleDateString("fr")}`,
    })
    .refine((date) => !maxDate || date <= maxDate, {
      message: `Veuillez choisir une date antérieure au ${(
        maxDate as Date
      ).toLocaleDateString("fr")}`,
    });

const utc = () =>
  z
    .number()
    .refine((n) => n >= -12 && n <= 14, {
      message: "La valeur UTC doit se trouver dans l'intervalle [-12, 14].",
    })
    .default(1); // France UTC

const id = (message?: string) =>
  z.number().refine((n) => n >= 0, {
    message: message ?? "Veuillez choisir un identifiant valide.",
  });

export {
  date,
  email,
  emptyfile,
  file,
  id,
  mandatoryPhone,
  mandatoryString,
  optionalPhone,
  optionnalFile,
  optionnalString,
  password,
  utc,
};
