import { TabsTrigger, TabsList, Tabs } from "@/components/atoms/Tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import PersonnalInformation from "./PersonnalInformation";
import Certificates from "./Certificates";

enum ProfileTabs {
  Information = "Information",
  Certificates = "Certificates",
}

interface ProfileProps {
  openCertificatePopup: () => void;
}

const Profile = ({ openCertificatePopup }: ProfileProps) => {
  return (
    <div className="flex p-8 px-28 flex-col flex-start gap-8 self-stretch">
      <h1>Mon profil</h1>
      <Tabs
        defaultValue={ProfileTabs.Information}
        className="flex flex-col gap-8"
      >
        <TabsList className="p-0.5 h-full w-fit">
          <TabsTrigger className="m-0" value={ProfileTabs.Information}>
            Mes informations
          </TabsTrigger>
          <TabsTrigger value={ProfileTabs.Certificates}>
            Mes certificats
          </TabsTrigger>
        </TabsList>
        <TabsContent value={ProfileTabs.Information}>
          <PersonnalInformation />
        </TabsContent>
        <TabsContent value={ProfileTabs.Certificates}>
          <Certificates openCertificatePopup={openCertificatePopup} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Profile;
