import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  FileAskUploadDto,
  FilesAskUploadDto,
  FilesUploadDtoClient,
  FileUploadDtoClient,
} from "../dto/file.dto";
import { keyFactory } from "../keyFactory";
import { ExamOptionsDto } from "./dto/exam-options.dto";

// create or reset draft

const createResetDraft = async () => {
  return await axios.post<null>(ENDPOINT.examRegistration.create);
};

export const useCreateResetDraftMutation = (
  props?: UseMutationOptions<AxiosResponse<null>, AxiosError, void>
) => {
  return useMutation({
    mutationFn: createResetDraft,
    ...props,
  });
};

// set current draft options

const setOptions = async (data: ExamOptionsDto) => {
  return await axios.post<null>(ENDPOINT.examRegistration.setOptions, data);
};

export const useSetOptionsMutation = (
  props?: UseMutationOptions<AxiosResponse<null>, AxiosError, ExamOptionsDto>
) => {
  return useMutation({
    mutationFn: setOptions,
    ...props,
  });
};

// get current draft options

const getCurrentOptions = async () => {
  return axios
    .get<ExamOptionsDto | "no-draft">(ENDPOINT.examRegistration.getOptions)
    .then((res) => res.data);
};

export const useGetCurrentOptionsQuery = (
  props?: UseQueryOptions<ExamOptionsDto | "no-draft", AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.examRegistrationCurrentOptions(),
    queryFn: getCurrentOptions,
    ...props,
  });
};

// confirm registration

const confirmRegistration = async () => {
  return await axios.post<void>(ENDPOINT.examRegistration.confirm);
};

export const useConfirmRegistrationMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, void>
) => {
  return useMutation({
    mutationFn: confirmRegistration,
    ...props,
  });
};

// ask reduced price document upload link

const askUploadDocument = async (data: FileAskUploadDto) => {
  return await axios
    .post<FileUploadDtoClient>(
      ENDPOINT.examRegistration.askUploadReducedPriceJustification,
      data
    )
    .then((res) => res.data);
};

export const useAskUploadDocumentMutation = (
  props?: UseMutationOptions<FileUploadDtoClient, AxiosError, FileAskUploadDto>
) => {
  return useMutation({
    mutationFn: askUploadDocument,
    ...props,
  });
};

// ask formation certificates upload links

const askUploadFormationCertificates = async (data: FilesAskUploadDto) => {
  return axios
    .post<FilesUploadDtoClient>(
      ENDPOINT.examRegistration.askUploadFormationCertificates,
      data
    )
    .then((res) => res.data);
};

export const useAskUploadFormationCertificatesMutation = (
  props?: UseMutationOptions<
    FilesUploadDtoClient,
    AxiosError,
    FilesAskUploadDto
  >
) => {
  return useMutation({
    mutationFn: askUploadFormationCertificates,
    ...props,
  });
};
