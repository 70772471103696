export enum PersonnalInfoFields {
  Sex = "personnalInfo.sex",
  LastName = "personnalInfo.lastName",
  FirstName = "personnalInfo.firstName",
  Birthdate = "personnalInfo.birthdate",
  BirthCity = "personnalInfo.birthCity",
  BirthCountry = "personnalInfo.birthCountry",
  Nationality = "personnalInfo.nationality",
  HomePhone = "personnalInfo.homePhone",
  MobilePhone = "personnalInfo.mobilePhone",
  Email = "credentials.email",
  EmailConfirmation = "credentials.emailConfirmation",
  Password = "credential.key",
  PasswordConfirmation = "credential.keyConfirmation",
}
