import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { CandidateInfoDto } from "../auth/dto/change-candidate-info.dto";
import { CertificateDto } from "../dto/certificate.dto";
import { FileAskUploadDto, FileUploadDtoClient } from "../dto/file.dto";
import { DelegationDtoClient } from "../exam-registration/dto/delegation.dto";
import { keyFactory } from "../keyFactory";

// candidate info

const getInfo = async () => {
  return axios.get(ENDPOINT.candidate.info).then((res) => res.data);
};

export const useGetCandidateinfoQuery = (
  props?: Partial<UseQueryOptions<CandidateInfoDto, AxiosError>>
) => {
  return useQuery({
    queryKey: keyFactory.candidateInfo(),
    queryFn: getInfo,
    ...props,
  });
};

const changeInfo = async (data: CandidateInfoDto) => {
  return await axios.post(ENDPOINT.candidate.changeInfo, data);
};

export const useChangeCandidateinfoMutation = (
  props?: UseMutationOptions<AxiosResponse, AxiosError, CandidateInfoDto>
) => {
  return useMutation({
    mutationFn: changeInfo,
    ...props,
  });
};

// get candidate certificates

const getCertificates = async () => {
  return axios
    .get<CertificateDto[]>(ENDPOINT.candidate.certificates)
    .then((res) => res.data);
};

export const useCandidateCertificatesQuery = (
  props?: UseQueryOptions<CertificateDto[], AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.candidateCertificates(),
    queryFn: getCertificates,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};

// get upload

const askUploadLink = async (data: FileAskUploadDto) => {
  return axios
    .post<FileUploadDtoClient>(ENDPOINT.candidate.certificateAskUpload, data)
    .then((res) => res.data);
};

export const useCandidateGetUploadLinkMutation = (
  props?: Partial<
    UseMutationOptions<FileUploadDtoClient, AxiosError, FileAskUploadDto>
  >
) => {
  return useMutation({
    mutationFn: askUploadLink,
    ...props,
  });
};

const addCertificate = async (data: CertificateDto) => {
  return axios
    .post<void>(ENDPOINT.candidate.certificateUpload, data)
    .then((res) => res.data);
};

export const useAddCertificateMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, CertificateDto>>
) => {
  return useMutation({
    mutationFn: addCertificate,
    ...props,
  });
};

// get delegations

const getDelegations = async () => {
  return axios
    .get<DelegationDtoClient[]>(ENDPOINT.candidate.delegations)
    .then((res) => res.data);
};

export const useDelegationsQuery = (
  props?: UseQueryOptions<DelegationDtoClient[], AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.delegations(),
    queryFn: getDelegations,
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });
};
