import { ExamOptionsDto } from "@/api/exam-registration/dto/exam-options.dto";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  mandatoryAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import { PhoneInput } from "@/components/molecules/PhoneInput";
import { email, optionalPhone, optionnalString } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import NextStepButton from "./NextStepButtons";

interface DelegationProps {
  onNextStep: (goNext: boolean) => void;
  registrationDraft: ExamOptionsDto;
  changeDraft: (newOptions: ExamOptionsDto) => void;
}

const delegationSchema = z.discriminatedUnion("hasDelegation", [
  z.object({
    hasDelegation: z.literal(true),
    delegationProperties: z.object({
      name: optionnalString(),
      surname: optionnalString(),
      email: email(),
      phoneNumber: optionalPhone(),
      companyName: optionnalString(),
    }),
  }),
  z.object({
    hasDelegation: z.literal(false),
    billingAddress: mandatoryAddressSchema,
  }),
]);

const delegationDefaultValues = {
  companyName: "",
  email: "",
  name: "",
  phoneNumber: "",
};

const Payement = ({
  onNextStep,
  registrationDraft,
  changeDraft,
}: DelegationProps) => {
  // FIXME: uncontrolled to controlled input error
  // this is likely due to the below code setting values
  // conditionnaly to the `hasDelegation` field

  const form = useForm<z.infer<typeof delegationSchema>>({
    resolver: zodResolver(delegationSchema),
    defaultValues: {
      hasDelegation: registrationDraft.hasDelegation ?? false,
      delegationProperties:
        registrationDraft.delegationProperties ?? delegationDefaultValues,
      billingAddress: registrationDraft.billingAddress ?? addressDefaultValues,
    },
  });

  const { watch, reset } = form;
  const hasDelegation = watch("hasDelegation");

  useEffect(() => {
    if (hasDelegation) {
      reset({
        hasDelegation: true,
        delegationProperties:
          registrationDraft.delegationProperties || delegationDefaultValues,
      });
    } else {
      reset({
        hasDelegation: false,
        billingAddress:
          registrationDraft.billingAddress || addressDefaultValues,
      });
    }
  }, [
    hasDelegation,
    reset,
    registrationDraft.billingAddress,
    registrationDraft.delegationProperties,
  ]);

  const onSubmit = (
    values: z.infer<typeof delegationSchema>,
    goNext: boolean
  ) => {
    const { data, error, success } = delegationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    changeDraft({
      hasDelegation: data.hasDelegation,
      ...(data.hasDelegation && {
        delegationProperties: data.delegationProperties,
      }),
      ...(!data.hasDelegation && { billingAddress: data.billingAddress }),
    });

    onNextStep(goNext);
  };

  const { control } = form;

  return (
    <div className="flex flex-col gap-8 items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-6 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Délégation</h1>
            <span className="text-gray-600 text-sm font-normal">
              La délégation permet la prise en charge du paiement de vos frais
              d'inscription, directement par votre entreprise (délégataire
              désigné). Le délégataire peut également gérer le dépôt des
              documents requis pour l'inscription (attestation(s) de formation)
              et a un accès à la note de débit, la convocation et l'attestation
              de présence. Vos autres données et documents restent personnels et
              confidentiels.Vous restez responsable du suivi de votre
              inscription et nous vous invitons à vous connecter régulièrement à
              votre espace pour consulter l'avancement de votre inscription.
            </span>
            <FormField
              control={form.control}
              name="hasDelegation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold text-black">
                    Souhaitez-vous déléguer à une société le paiement de votre
                    examen ?
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      defaultValue={String(field.value)}
                      onValueChange={(val) => field.onChange(val === "true")}
                      className="flex flex-row gap-6 w-full mt-1"
                    >
                      {[
                        {
                          value: false,
                          msg: "Non",
                        },
                        {
                          value: true,
                          msg: "Oui",
                        },
                      ].map(({ value, msg }) => (
                        <div
                          className="flex items-center self-stretch"
                          key={String(value)}
                        >
                          <RadioGroupItem
                            key={String(value)}
                            value={String(value)}
                            className="flex items-center justify-center gap-2"
                          />
                          <div>{msg}</div>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex items-start flex-col p-4 gap-2 self-stretch border border-gray-200 rounded-sm">
              {hasDelegation && (
                <>
                  <h2>Informations personnelles du délégataire désigné</h2>
                  <FormField
                    control={control}
                    name="delegationProperties.email"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Adress email *</FormLabel>
                        <FormControl>
                          <Input placeholder={"-"} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex flex-row w-full gap-2">
                    <FormField
                      control={control}
                      name="delegationProperties.name"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Nom</FormLabel>
                          <FormControl>
                            <Input placeholder={"-"} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="delegationProperties.surname"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Prénom</FormLabel>
                          <FormControl>
                            <Input placeholder={"-"} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={control}
                    name="delegationProperties.phoneNumber"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Téléphone</FormLabel>
                        <FormControl>
                          <PhoneInput
                            defaultCountry="FR"
                            placeholder={"06 XX XX XX XX"}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="delegationProperties.companyName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Nom société</FormLabel>
                        <FormControl>
                          <Input placeholder={"-"} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}

              {!hasDelegation && (
                <AddressForm
                  fieldName="billingAddress"
                  title="Votre adresse de facturation"
                  isMandatory={true}
                />
              )}
            </div>
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data, true))}
            onPrevious={() => onNextStep(false)}
          />
        </form>
      </Form>
    </div>
  );
};

export default Payement;
