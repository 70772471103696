import axios from "axios";
import { createRoot } from "react-dom/client";
import "unfonts.css";
import AppProviders from "./AppProviders.tsx";
import "./styles/index.css";

const API_URL = import.meta.env.VITE_API_URL;
const API_PORT = import.meta.env.VITE_API_PORT;

axios.defaults.baseURL = `${API_URL}:${API_PORT}`;
axios.defaults.withCredentials = true;

createRoot(document.getElementById("root")!).render(<AppProviders />);
