import { MerchandiseClass } from "@/api/enums/MerchandiseClass";
import { TransportMode } from "@/api/enums/TransportMode";
import { z } from "zod";

const merchandisesSchema = z
  .enum([
    MerchandiseClass.CHEMICAL,
    MerchandiseClass.EXPLOSIVES,
    MerchandiseClass.GAS,
    MerchandiseClass.HYDROCARBONS,
    MerchandiseClass.RADIOACTIVE,
  ])
  .array()
  .superRefine((array, ctx) => {
    if (array.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez choisir au moins 1 type de marchandises",
      });
    }
  });

const transportsSchema = z
  .enum([TransportMode.BOAT, TransportMode.ROAD, TransportMode.TRAIN])
  .array()
  .superRefine((array, ctx) => {
    if (array.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez choisir au moins 1 moyen de transport",
      });
    }
  });

const perimeterSchema = z.object({
  merchandises: merchandisesSchema,
  transportModes: transportsSchema,
});

type Perimeter = z.infer<typeof perimeterSchema>;

class PerimeterUtil {
  public static emptyPerimeter = (): Perimeter => ({
    merchandises: [],
    transportModes: [],
  });

  public static perimeterToCode = ({
    transportModes,
    merchandises,
  }: Perimeter) => {
    return (
      (transportModes.includes(TransportMode.ROAD) ? "R" : "") +
      (transportModes.includes(TransportMode.TRAIN) ? "F" : "") +
      (transportModes.includes(TransportMode.BOAT) ? "B" : "") +
      (merchandises.includes(MerchandiseClass.EXPLOSIVES) ? "1" : "") +
      (merchandises.includes(MerchandiseClass.GAS) ? "2" : "") +
      (merchandises.includes(MerchandiseClass.RADIOACTIVE) ? "7" : "") +
      (merchandises.includes(MerchandiseClass.CHEMICAL) ? "C" : "") +
      (merchandises.includes(MerchandiseClass.HYDROCARBONS) ? "H" : "")
    );
  };

  public static codeToPerimeter = (code: string): Perimeter => {
    const transportModes = [];
    const merchandises = [];

    if (code.includes("R")) transportModes.push(TransportMode.ROAD);
    if (code.includes("T")) transportModes.push(TransportMode.TRAIN);
    if (code.includes("B")) transportModes.push(TransportMode.BOAT);

    if (code.includes("1")) merchandises.push(MerchandiseClass.EXPLOSIVES);
    if (code.includes("2")) merchandises.push(MerchandiseClass.GAS);
    if (code.includes("7")) merchandises.push(MerchandiseClass.RADIOACTIVE);
    if (code.includes("C")) merchandises.push(MerchandiseClass.CHEMICAL);
    if (code.includes("H")) merchandises.push(MerchandiseClass.HYDROCARBONS);

    return {
      transportModes,
      merchandises,
    };
  };

  public static perimetersUnion = (...perimeters: Perimeter[]): Perimeter => {
    if (perimeters.length === 0)
      return { merchandises: [], transportModes: [] };

    return perimeters.reduce(
      (acc, perimeter) => {
        return {
          merchandises: [
            ...new Set([...acc.merchandises, ...perimeter.merchandises]),
          ],
          transportModes: [
            ...new Set([...acc.transportModes, ...perimeter.transportModes]),
          ],
        };
      },
      { merchandises: [], transportModes: [] } // Initial accumulator value
    );
  };

  public static perimeterIncludes = (
    including: Perimeter,
    included: Perimeter
  ): boolean => {
    return (
      included.transportModes.every((mode) =>
        including.transportModes.includes(mode)
      ) &&
      included.merchandises.every((mode) =>
        including.merchandises.includes(mode)
      )
    );
  };

  public static perimeterRestrict = (
    original: Perimeter,
    restricted: Perimeter
  ): Perimeter => {
    return {
      merchandises: original.merchandises.filter((val) =>
        restricted.merchandises.includes(val)
      ),
      transportModes: original.transportModes.filter((val) =>
        restricted.transportModes.includes(val)
      ),
    };
  };

  public static fullPerimeter = (): Perimeter => ({
    merchandises: Object.values(MerchandiseClass),
    transportModes: Object.values(TransportMode),
  });
}

// eslint-disable-next-line react-refresh/only-export-components
export { merchandisesSchema, perimeterSchema, PerimeterUtil, transportsSchema };
export type { Perimeter };
