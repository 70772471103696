import HelloPageLayout from "../templates/hello-page";
import { companies, candidates, exams } from "@/constants/seed_data"; // adjust the import path as needed

// Extract and format the admin data from environment variables
const adminEmails: string[] =
  import.meta.env.VITE_ADMIN_EMAILS?.split(" ") || [];
const adminPasswords: string[] =
  import.meta.env.VITE_ADMIN_PASSWORDS?.split(" ") || [];

// Combine emails and passwords into a list of admin objects
const admins = adminEmails.map((email, index) => ({
  email,
  password: adminPasswords[index] || "N/A",
}));

const SeedData = () => {
  return (
    <HelloPageLayout maxWidth="max-w-3xl">
      <div>
        <h1 className="text-3xl font-extrabold text-center mb-6">
          Données de test
        </h1>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Admins
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {admins.map((admin, index) => (
              <div key={index} className="p-4 rounded-lg bg-blue-50 shadow-md">
                <p>
                  <span className="font-semibold">Email:</span> {admin.email}
                </p>
                <p>
                  <span className="font-semibold">Password:</span>{" "}
                  {admin.password}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Companies
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {companies.map((company, index) => (
              <div key={index} className="p-4 rounded-lg bg-green-50 shadow-md">
                <p>
                  <span className="font-semibold">Email:</span> {company.email}
                </p>
                <p>
                  <span className="font-semibold">Role:</span> {company.role}
                </p>
                <p>
                  <span className="font-semibold">Password:</span> {company.key}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Candidates
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {candidates.map((candidate, index) => (
              <div
                key={index}
                className="p-4 rounded-lg bg-purple-50 shadow-md"
              >
                <p>
                  <span className="font-semibold">Email:</span>{" "}
                  {candidate.email}
                </p>
                <p>
                  <span className="font-semibold">Role:</span> {candidate.role}
                </p>
                <p>
                  <span className="font-semibold">Password:</span>{" "}
                  {candidate.key}
                </p>
                <p>
                  <span className="font-semibold">Name:</span>{" "}
                  {candidate.candidate.create.firstName}{" "}
                  {candidate.candidate.create.lastName}
                </p>
                <p>
                  <span className="font-semibold">Sex:</span>{" "}
                  {candidate.candidate.create.sex}
                </p>
                <p>
                  <span className="font-semibold">Birthdate:</span>{" "}
                  {candidate.candidate.create.birthdate.toDateString()}
                </p>
                <p>
                  <span className="font-semibold">Birth City:</span>{" "}
                  {candidate.candidate.create.birthCity}
                </p>
                <p>
                  <span className="font-semibold">Nationality:</span>{" "}
                  {candidate.candidate.create.nationality}
                </p>
                <p>
                  <span className="font-semibold">Phone:</span>{" "}
                  {candidate.candidate.create.mobilePhone}
                </p>
                <p>
                  <span className="font-semibold">City:</span>{" "}
                  {candidate.candidate.create.city}
                </p>
                <p>
                  <span className="font-semibold">Country:</span>{" "}
                  {candidate.candidate.create.country}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Exams
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {exams.map((exam, index) => (
              <div
                key={index}
                className="p-4 rounded-lg bg-yellow-50 shadow-md"
              >
                <p>
                  <span className="font-semibold">Number:</span> {exam.number}
                </p>
                <p>
                  <span className="font-semibold">Type:</span> {exam.type}
                </p>
                <p>
                  <span className="font-semibold">Registering Start Date:</span>{" "}
                  {exam.registeringStartDate.toDateString()}
                </p>
                <p>
                  <span className="font-semibold">Registering End Date:</span>{" "}
                  {exam.registeringEndDate.toDateString()}
                </p>
                <p>
                  <span className="font-semibold">
                    Formation Certificate End Date:
                  </span>{" "}
                  {exam.formationCertificateEndDate.toDateString()}
                </p>
                <p>
                  <span className="font-semibold">Date:</span>{" "}
                  {exam.date.toDateString()}
                </p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </HelloPageLayout>
  );
};

export default SeedData;
