import { cn } from "@/lib/utils";
import { QueryObserverResult } from "@tanstack/react-query";
import { CircleX } from "lucide-react";

export interface ISVGProps extends React.SVGProps<SVGSVGElement> {
  isLoading?: boolean;
  loadingMessage?: string;
  isError?: boolean;
  errorMessage?: string;
  size?: number;
  className?: string;
}

// https://github.com/shadcn-ui/ui/discussions/1694#discussioncomment-7851248
export const LoadingSpinner = ({
  size = 24,
  className,
  isLoading = true,
  loadingMessage = "",
  isError = false,
  errorMessage = "",
  ...props
}: ISVGProps) => {
  return isLoading ? (
    <div className="flex flex-row gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={cn("animate-spin", className)}
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
      {loadingMessage}
    </div>
  ) : isError ? (
    <div className="flex flex-row gap-2">
      <CircleX className="w-6 h-6 text-red-700" />
      {errorMessage}
    </div>
  ) : (
    <></>
  );
};

interface QueryLoadingSpinnerProps {
  loadingMessage?: string;
  errorMessage?: string;
  queries: QueryObserverResult[];
}

export const QueryLoadingSpinner = ({
  queries,
  errorMessage,
  loadingMessage,
}: QueryLoadingSpinnerProps) => {
  return (
    <LoadingSpinner
      errorMessage={errorMessage}
      loadingMessage={loadingMessage}
      isError={queries.some((query) => query.isError)}
      isLoading={queries.some((query) => query.isLoading)}
    />
  );
};
