import { PriceType } from "@/api/enums/PriceType";
import { ExamOptionsDto } from "@/api/exam-registration/dto/exam-options.dto";
import { useAskUploadDocumentMutation } from "@/api/exam-registration/exam-registration";
import { uploadDocument, useFilesQuery } from "@/api/s3/s3";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/atoms/Form";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import { emptyfile, file } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { z } from "zod";
import NextStepButton from "./NextStepButtons";
import { Prices } from "@/constants/prices";

interface PricingProps {
  onNextStep: (goNext: boolean) => void;
  registrationDraft: ExamOptionsDto;
  changeDraft: (newOptions: ExamOptionsDto) => void;
}

const priceTypeFormSchema = z.discriminatedUnion("priceType", [
  z.object({
    priceType: z.literal(PriceType.REDUCED),
    reducedPriceFile: file(),
  }),
  z.object({
    priceType: z.literal(PriceType.FULL),
  }),
]);

const Pricing = ({
  onNextStep,
  registrationDraft,
  changeDraft,
}: PricingProps) => {
  const filesQuery = useFilesQuery(
    registrationDraft.reducedPriceDocument
      ? [registrationDraft.reducedPriceDocument]
      : []
  );

  // api calls

  const askUploadDocumentMutation = useAskUploadDocumentMutation();

  // form

  const form = useForm<z.infer<typeof priceTypeFormSchema>>({
    resolver: zodResolver(priceTypeFormSchema),
    defaultValues: {
      priceType: registrationDraft.priceType ?? PriceType.FULL,
      reducedPriceFile: emptyfile(),
    },
  });

  const onSubmit = (
    values: z.infer<typeof priceTypeFormSchema>,
    goNext: boolean
  ) => {
    const { data, error, success } = priceTypeFormSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    if (
      data.priceType === PriceType.REDUCED &&
      (filesQuery.length === 0 || filesQuery[0].data !== data.reducedPriceFile)
    ) {
      if (!data.reducedPriceFile) {
        console.error("no justification : this shouldn't happen");
        return;
      }

      // upload document
      askUploadDocumentMutation.mutate(
        {
          fileName: data.reducedPriceFile.name,
        },
        {
          onSuccess: async (uploadData) => {
            if (!uploadData) {
              console.error("failed to receive upload link");
              return;
            }

            if (!data.reducedPriceFile) {
              console.error("no justification : this shouldn't happen");
              return;
            }

            const { fileKey, putObjectURL, fileName } = uploadData;
            await uploadDocument(putObjectURL, data.reducedPriceFile);

            changeDraft({
              priceType: data.priceType,
              reducedPriceDocument: {
                fileKey,
                fileName,
                signedAccessKey: uploadData.signedAccessKey,
              },
            });

            onNextStep(goNext);
          },
        }
      );
    } else {
      changeDraft({
        priceType: data.priceType,
      });

      onNextStep(goNext);
    }
  };

  // load file if there is one
  const formSetValue = form.setValue;

  useDeepCompareEffect(() => {
    const fetchFile = async () => {
      if (!filesQuery?.[0]) return;
      formSetValue("reducedPriceFile", filesQuery[0].data as File);
    };

    fetchFile();
  }, [registrationDraft, formSetValue, filesQuery]);

  // don't show page if files are not loaded
  if (filesQuery.some((query) => query.isLoading))
    return <div>Chargement du brouillon...</div>;
  if (filesQuery.some((query) => query.isError))
    return <div>Erreur lors du chargement des anciens certificats.</div>;

  return (
    <div className="flex flex-col gap-8 items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Tarif</h1>
            <FormField
              control={form.control}
              name="priceType"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <RadioGroup
                      defaultValue={field.value}
                      onValueChange={(val) => field.onChange(val as PriceType)}
                      className="flex flex-col gap-4 w-full mt-1"
                    >
                      {[
                        {
                          value: PriceType.FULL,
                          msg: `Tarif plein - ${Prices.FULL.toString()} €`,
                          key: PriceType.FULL,
                        },
                        {
                          value: PriceType.REDUCED,
                          msg: `Tarif réduit - ${Prices.REDUCED.toString()} € (uniquement étudiant ou demandeur d'emploi)`,
                          key: PriceType.REDUCED,
                        },
                      ].map(({ value, msg, key }) => (
                        <div
                          className="flex items-center self-stretch"
                          key={key}
                        >
                          <RadioGroupItem
                            key={value}
                            value={value}
                            className="flex items-center justify-center gap-2"
                          />
                          <div>{msg}</div>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
            {form.watch("priceType") === PriceType.REDUCED && (
              <DropzoneMolecule
                fieldName="reducedPriceFile"
                canBeRemoved={true}
              >
                <span className="text-center">
                  <span className="text-brand-700">Déposez</span> ou cliquez
                  pour déposer votre justificatif étudiant ou demandeur d'emploi{" "}
                  <br />
                  <span className="text-gray-600 text-sm font-normal">
                    Pour les demandeurs d'emploi, le justificatif doit dater de
                    moins de 3 mois. Il sera contrôlé par le CIFMD. Si celui-ci
                    n'est pas recevable, le tarif plein vous sera appliqué.
                  </span>
                </span>
              </DropzoneMolecule>
            )}
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data, true))}
            onPrevious={() => onNextStep(false)}
          />
        </form>
      </Form>
    </div>
  );
};

export default Pricing;
