import { replaceNullsWith } from "@/api/app";
import {
  useChangeCandidateinfoMutation,
  useGetCandidateinfoQuery,
} from "@/api/candidate/candidate";
import { keyFactory } from "@/api/keyFactory";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import { Button } from "@atoms/Button";
import { Form } from "@atoms/Form";
import { toast } from "@atoms/Toast/UseToast";
import { zodResolver } from "@hookform/resolvers/zod";
import AddressForm from "@molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionnalAddressSchema,
} from "@molecules/AddressForm/AddressFormSchema";
import ErrorDisplay from "@molecules/ErrorDisplay";
import { SuccessToast } from "@molecules/ToastsTemplates";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { PersonnalInfoFields } from "../../../../../molecules/PersonnalInfoForm/PersonnalInfoFields";
import PersonnalInformationForm from "../../../../../molecules/PersonnalInfoForm/PersonnalInfoForm";
import {
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "../../../../../molecules/PersonnalInfoForm/PersonnalInfoFormSchema";

const accountModificationSchema = z.object({
  address: optionnalAddressSchema,
  personnalInfo: personnalInfoSchema,
});

interface CheckInfoProps {
  onNextStep: (goNext: boolean) => void;
}

const CheckInfo = ({ onNextStep }: CheckInfoProps) => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState("");

  // api calls

  const getCandidateQuery = useGetCandidateinfoQuery({
    staleTime: Infinity,
  });

  const changeCandidateInfoMutation = useChangeCandidateinfoMutation({
    onSuccess: async () => {
      setErrorMsg("");
      toast({
        action: SuccessToast({
          title: "",
          description: "Vos informations ont été modifiées avec succès.",
        }),
        duration: 3000,
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidateInfo(),
      });
    },
    onError: (error) => {
      handleErrorCode(error);
    },
  });

  // form

  const form = useForm<z.infer<typeof accountModificationSchema>>({
    resolver: zodResolver(accountModificationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
    },
  });

  const { data: candidateInfo } = getCandidateQuery;

  useEffect(() => {
    if (!candidateInfo) return;

    form.reset({
      address:
        replaceNullsWith(candidateInfo.address, "") || addressDefaultValues,
      personnalInfo:
        replaceNullsWith(candidateInfo.personnalInfo, "") ||
        personnalInfoDefaultValues,
    });
  }, [
    candidateInfo,
    candidateInfo?.personnalInfo,
    candidateInfo?.address,
    form,
  ]);

  const handleErrorCode = (error: AxiosError) => {
    switch (error.status) {
      case HttpStatusCode.InternalServerError:
        setErrorMsg(
          "Le serveur a rencontré une erreur lors de la modification de vos informations."
        );
        return;

      default:
        setErrorMsg(
          "Une erreur est survenue lors de la modification de vos informations."
        );
        return;
    }
  };

  const onSubmit = (values: z.infer<typeof accountModificationSchema>) => {
    const { data, error, success } =
      accountModificationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    changeCandidateInfoMutation.mutate(data);
  };

  return (
    <div>
      <div className="flex p-4 flex-col items-start g-4 self-stretch rounded-md border-gray-200 bg-white border w-full">
        <h1>Vérifiez vos informations et modifiez les si besoin</h1>
        <span>
          L'adresse email de votre compte candidat est celle qui sera transmise
          à l'Etat dans le cadre de la télédéclaration.
        </span>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-8"
          >
            <PersonnalInformationForm
              hiddenFields={[
                PersonnalInfoFields.Email,
                PersonnalInfoFields.Password,
              ]}
              disabledFields={Object.values(PersonnalInfoFields)}
            />
            <AddressForm
              fieldName="address"
              disabledFields={Object.values(AddressFields)}
            />
            {errorMsg && <ErrorDisplay msg={errorMsg} />}
          </form>
        </Form>
      </div>
      <Button
        className="flex w-full font-semibold bg-brand-600 text-white mt-8"
        onClick={() => onNextStep(true)}
      >
        {"S'inscrire à une session d'examen"}
      </Button>
    </div>
  );
};

export default CheckInfo;
