const keyFactory = {
  hello: () => ["hello"],
  role: () => ["role"],
  formationInstitutes: () => ["formationInstitutes"],

  // exam
  exam: () => ["exam"],
  examAll: () => keyFactory.exam().concat(["all"]),

  // exam registration
  examRegistration: () => ["examRegistration"],
  examRegistrationCurrentOptions: () =>
    keyFactory.examRegistration().concat(["currentOptions"]),
  delegations: () => keyFactory.examRegistration().concat(["delegations"]),

  // files
  file: (key: string) => ["file", key],

  // candidate
  candidate: () => ["candidate"],
  candidateInfo: () => keyFactory.candidate().concat(["info"]),
  candidateCertificates: () => keyFactory.candidate().concat(["certificates"]),

  // candidate
  company: () => ["company"],
  companyInfo: () => keyFactory.company().concat(["info"]),
};

export { keyFactory };
