import { date, file, mandatoryString } from "@/constants/zodTypes";
import {
  PerimeterUtil,
  perimeterSchema,
} from "@molecules/PerimeterForm/PerimeterFormSchema";
import { z } from "zod";

const certificateSchema = z.object({
  number: mandatoryString(),
  country: mandatoryString(),
  expirationDate: date(
    new Date(),
    new Date(new Date().getTime() + 100 * 365 * 24 * 60 * 60 * 1000)
  ),
  file: file(),
  perimeter: perimeterSchema,
});

const certificateDefaultValues: z.infer<typeof certificateSchema> = {
  number: "",
  expirationDate: new Date(),
  country: "",
  file: new File([""], ""),
  perimeter: PerimeterUtil.emptyPerimeter(),
};

export { certificateDefaultValues, certificateSchema };
