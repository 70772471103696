import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@atoms/Dialog";
import { Button } from "@atoms/Button";
import { DialogDescription } from "@radix-ui/react-dialog";

interface ConfirmDialogProps {
  isOpen: boolean;
  onResult: (wasConfirmed: boolean) => void;
  title: string;
  message: string;
  validateStr: string;
  cancelStr: string;
}

export function ConfirmDialog({
  isOpen,
  onResult,
  title,
  message,
  validateStr,
  cancelStr,
}: ConfirmDialogProps) {
  return (
    <Dialog open={isOpen}>
      <DialogContent showCloseButton={false}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{message}</DialogDescription>
        <DialogFooter>
          <Button className="w-full" onClick={() => onResult(false)}>
            {cancelStr}
          </Button>
          <Button className="w-full" onClick={() => onResult(true)}>
            {validateStr}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
