const ROUTE = {
  home: "/",
  connectionHome: "/home",
  seedData: "/seed-data",
  admin: {
    login: "/admin/login",
    dashboard: "/admin/dashboard",
  },
  candidate: {
    accountCreation: "/candidate/account-creation",
    dashboard: "/candidate/dashboard",
    examsRegistering: {
      createDraft: "/exam-registration/create",
      setOptions: "/exam-registration/set-options",
      confirmRegistration: "/exam-registration/confirm-registration",
    },
  },
  company: {
    dashboard: "/company/dashboard",
    accountCreation: "/company/account-creation",
  },
  forgotten: {
    password: {
      ask: "/forgotten/password/ask",
      reset: "/forgotten/password/reset",
    },
    email: {
      ask: "/forgotten/email/ask",
    },
  },
};

export { ROUTE };
