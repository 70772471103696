import axios, { AxiosError } from "axios";
import { FileDto } from "../dto/file.dto";
import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import { keyFactory } from "../keyFactory";

const uploadDocument = async (uploadLink: string, file: File) => {
  await axios.put(uploadLink, file, {
    headers: {
      "Content-Type": file.type,
    },
    withCredentials: false,
  });
};

const fetchFileFromSignedUrl = async (
  signedAccessKey: string,
  fileName: string
): Promise<File> => {
  if (!signedAccessKey) {
    throw new Error("Signed access key is required to fetch the file.");
  }

  const response = await axios.get(signedAccessKey, {
    responseType: "blob",
  });

  return new File([response.data], fileName, {
    type: response.data.type,
  });
};

const useFilesQuery = (
  fileDtos: FileDto[],
  queryOptions?: UseQueryOptions<File, AxiosError>
) => {
  return useQueries({
    queries: fileDtos.map((fileDto) => ({
      queryKey: keyFactory.file(fileDto.fileKey),
      queryFn: () =>
        fetchFileFromSignedUrl(
          fileDto.signedAccessKey as string,
          fileDto.fileName
        ),
      gcTime: 1000 * 60 * 60,
      staleTime: Infinity,
      enabled: fileDto.signedAccessKey !== undefined,
      ...queryOptions,
    })),
  });
};

export { uploadDocument, useFilesQuery };
