import { useFormationInstitutesQuery } from "@/api/app";
import { FileDto } from "@/api/dto/file.dto";
import { FormationInstituteDtoClient } from "@/api/dto/formation-institutes.dto";
import { FormationType } from "@/api/enums/FormationType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { ExamOptionsDto } from "@/api/exam-registration/dto/exam-options.dto";
import { useAskUploadFormationCertificatesMutation } from "@/api/exam-registration/exam-registration";
import { uploadDocument, useFilesQuery } from "@/api/s3/s3";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { ComboboxField } from "@/components/molecules/Combobox";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import { PerimeterForm } from "@/components/molecules/PerimeterForm/PerimeterForm";
import {
  PerimeterUtil,
  Perimeter,
  perimeterSchema,
} from "@/components/molecules/PerimeterForm/PerimeterFormSchema";
import { file } from "@/constants/zodTypes";
import useChildForm from "@/hooks/ChildForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { Info, Plus, Trash2 } from "lucide-react";
import { useFieldArray, useForm } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { z } from "zod";
import NextStepButton from "./NextStepButtons";
import { MerchandiseClass } from "@/api/enums/MerchandiseClass";

interface FormationCertificateFormProps {
  fieldName: string;
  id: number;
  formationInstitutes: FormationInstituteDtoClient[];
  forbiddenFormationTypes?: FormationType[];
  hiddenPerimeter?: Perimeter;
  remove?: (() => void) | null;
}

const FormationCertificateForm = ({
  id,
  fieldName,
  formationInstitutes,
  forbiddenFormationTypes = [],
  hiddenPerimeter = PerimeterUtil.emptyPerimeter(),
  remove = null,
}: FormationCertificateFormProps) => {
  const { transformPath, control } = useChildForm(fieldName);

  return (
    <div className="max-w-full flex flex-col gap-2">
      <h2>Attestation N°{id + 1}</h2>
      <div className="flex flex-row w-full gap-2 mb-4">
        <FormField
          control={control}
          name={transformPath("formationType")}
          render={({ field }) => (
            <FormItem className="w-1/2">
              <FormLabel>Type de formation</FormLabel>
              <FormControl>
                <ComboboxField
                  options={[
                    {
                      label:
                        "Formation dispensée par un Organisme de Formation (OF)",
                      value: FormationType.INSTITUTE,
                    },
                    {
                      label:
                        "Attestation sur l'honneur d'un maintien à jour des connaissances réglementaires couvrant la version applicable des Règlements à la date de l'examen.",
                      value: FormationType.ON_HONOR,
                    },
                    {
                      label:
                        "Formation dispensée par votre entreprise, en interne",
                      value: FormationType.COMPANY,
                    },
                  ].filter(
                    ({ value }) => !forbiddenFormationTypes.includes(value)
                  )}
                  placeholder="-"
                  value={field.value}
                  onChange={field.onChange}
                  contentWidth={400}
                  displaySearchBox={false}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={transformPath("formationInstituteId")}
          render={({ field }) => (
            <FormItem className="w-1/2">
              <FormLabel>Organisme de formation</FormLabel>
              <FormControl>
                <ComboboxField
                  options={formationInstitutes.map((institute) => ({
                    label: institute.description,
                    value: institute.id.toString(),
                  }))}
                  placeholder={""}
                  value={field.value.toString()}
                  onChange={(val) => field.onChange(Number(val))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <DropzoneMolecule
        fieldName={transformPath("certificate")}
        canBeRemoved={true}
        label=""
      />
      <PerimeterForm
        fieldName={transformPath("perimeter")}
        transportsTitleElement={
          <>Quel(s) mode(s) est/sont couvert(s) par l'attestation ?</>
        }
        merchandisesTitleElement={
          <>
            Quelle(s) classe(s) de marchandises dangereuses est/sont couverte(s)
            par l'attestation ?
          </>
        }
        hiddenPerimeter={hiddenPerimeter}
      />
      {remove !== null && (
        <Button
          className="w-full  gap-2 "
          variant={"red"}
          onClick={(e) => {
            e.preventDefault();
            remove();
          }}
        >
          Supprimer cette attestation
          <Trash2 className="w-5 h-5" />
        </Button>
      )}
    </div>
  );
};

interface CertificateProps {
  onNextStep: (goNext: boolean) => void;
  registrationDraft: ExamOptionsDto;
  changeDraft: (newOptions: ExamOptionsDto) => void;
}

const certificateSchema = z
  .object({
    formationType: z.nativeEnum(FormationType),
    formationInstituteId: z.number().optional(),
    certificate: file(),
    perimeter: perimeterSchema,
  })
  .superRefine(({ formationType, formationInstituteId }, ctx) => {
    if (
      formationType === FormationType.INSTITUTE &&
      formationInstituteId === -1
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez sélectionner un institut",
        path: ["formationInstituteId"],
      });
    }
  });

const certificatesSchema = z.object({
  data: certificateSchema
    .array()
    .min(1, "Veuillez fournir au moins 1 attestation"),
  allCertificatesProvided: z.boolean(),
});

const certificateDefaultValues: z.infer<typeof certificateSchema> = {
  formationType: FormationType.COMPANY,
  formationInstituteId: -1,
  certificate: new File([""], ""),
  perimeter: PerimeterUtil.emptyPerimeter(),
};

const Certificate = ({
  onNextStep,
  registrationDraft,
  changeDraft,
}: CertificateProps) => {
  ////////////
  // api calls
  ////////////

  const filesQuery = useFilesQuery(
    registrationDraft.formationCertificates?.map(
      (certificate) => certificate.certificate
    ) || []
  );

  const askUploadFormationCertificatesMutation =
    useAskUploadFormationCertificatesMutation();

  const formationInstitutesQuery = useFormationInstitutesQuery();

  ////////////
  // form
  ////////////

  const form = useForm<z.infer<typeof certificatesSchema>>({
    resolver: zodResolver(certificatesSchema),
    defaultValues: {
      data: Array(registrationDraft.formationCertificates?.length || 1).fill(
        certificateDefaultValues
      ),
      allCertificatesProvided:
        registrationDraft.allCertificatesProvided ?? false,
    },
  });

  const onSubmit = (
    { data, allCertificatesProvided }: z.infer<typeof certificatesSchema>,
    goNext: boolean
  ) => {
    const newFilesIndexes = data
      .filter(
        (certificate, i) =>
          i >= filesQuery.length ||
          certificate.certificate !== filesQuery[i].data
      )
      .map((_, i) => i);

    // no new certificates
    if (newFilesIndexes.length === 0) {
      if (!registrationDraft.formationCertificates) {
        console.error("wrong state");
        return;
      }

      changeDraft({
        allCertificatesProvided,
        formationCertificates: data.map(
          ({ formationType, perimeter, formationInstituteId }, i) => ({
            formationInstituteId,
            formationType,
            perimeter,
            certificate: registrationDraft.formationCertificates?.[i]
              .certificate as FileDto,
          })
        ),
      });

      onNextStep(goNext);
      return;
    }

    // new certificates to upload
    askUploadFormationCertificatesMutation.mutate(
      {
        askUploads: newFilesIndexes.map((i) => {
          return { fileName: data[i].certificate.name };
        }),
      },
      {
        onSuccess: async ({ uploadData }) => {
          if (uploadData.length !== newFilesIndexes.length) {
            console.error(
              `expected ${data.length} certificates, got ${uploadData.length}`
            );
            return;
          }

          // upload
          for (let i = 0; i < uploadData.length; i++)
            await uploadDocument(
              uploadData[i].putObjectURL,
              data[i].certificate
            );

          // mix new and old keys
          const keys: string[] = [];
          let new_files_index = 0;

          for (let i = 0; i < data.length; i++) {
            if (
              i < filesQuery.length &&
              data[i].certificate === filesQuery[i].data
            ) {
              if (!registrationDraft.formationCertificates) {
                console.error("wrong state");
                return;
              }

              keys.push(
                registrationDraft.formationCertificates[i].certificate.fileKey
              );
            } else {
              keys.push(uploadData[new_files_index].fileKey);
              new_files_index++;
            }
          }

          // update draft
          changeDraft({
            allCertificatesProvided,
            formationCertificates: data.map((val, i) => {
              return {
                formationType: val.formationType,
                ...(val.formationType === FormationType.INSTITUTE && {
                  formationInstituteId: val.formationInstituteId,
                }),
                perimeter: val.perimeter,
                certificate: {
                  fileKey: keys[i],
                  fileName: data[i].certificate.name,
                },
              };
            }),
          });

          onNextStep(goNext);
        },
      }
    );
  };

  // load existing files
  const { reset, getValues } = form;
  const allQueriesSuccessful = filesQuery.every((query) => query.isSuccess);
  const filesQueryData = filesQuery.map((query) => query.data);

  // using deepcompare because filesQuery object changes on every render
  // https://github.com/kentcdodds/use-deep-compare-effect
  useDeepCompareEffect(() => {
    if (!allQueriesSuccessful || filesQueryData.length === 0) return;

    const certificates = registrationDraft.formationCertificates;
    if (!certificates || certificates.length !== filesQueryData.length) {
      console.error("wrong state");
      return;
    }

    reset({
      data: certificates.map((certificate, i) => ({
        ...certificate,
        perimeter: certificate.perimeter,
        certificate: filesQueryData[i],
      })),
      allCertificatesProvided: getValues("allCertificatesProvided"),
    });
  }, [
    reset,
    getValues,
    filesQuery,
    allQueriesSuccessful,
    registrationDraft.formationCertificates,
    registrationDraft.allCertificatesProvided,
  ]);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "data",
  });

  // don't show page if files are not loaded
  if (filesQuery.some((query) => query.isLoading))
    return <div>Chargement du brouillon...</div>;
  if (filesQuery.some((query) => query.isError))
    return <div>Erreur lors du chargement des anciens certificats.</div>;

  return (
    <div className="flex flex-col gap-8 items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Attestation de formation</h1>
            <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
              <Info className="w-5 h-5 flex-shrink-0" />
              <div className="flex flex-col">
                <span className="font-bold">Dépôt obligatoire</span>
                <span className="text-gray-600 font-normal">
                  Vous devez déposer votre ou vos attestation(s) de formation
                  dès que possible et au plus tard 7 jours avant la date de
                  session d'examen. Si ce délai devait être dépassé, vous devez
                  nous contacter par mail. Il est recommandé de privilégier un
                  dépôt unique, regroupant toutes vos attestations en un seul
                  document
                </span>
              </div>
            </div>
            {fields.map((field, i) => (
              <FormationCertificateForm
                key={field.id}
                fieldName={`data.${i}`}
                id={i}
                formationInstitutes={formationInstitutesQuery.data || []}
                remove={i > 0 ? () => remove(i) : null}
                forbiddenFormationTypes={
                  registrationDraft.type === RegistrationType.RENEW
                    ? []
                    : [FormationType.ON_HONOR]
                }
                hiddenPerimeter={
                  registrationDraft.type === RegistrationType.INITIAL
                    ? {
                        merchandises: [MerchandiseClass.HYDROCARBONS],
                        transportModes: [],
                      }
                    : undefined
                }
              />
            ))}
            <Button
              variant={"white"}
              className="w-full text-gray-800 font-semibold text-sm gap-2"
              onClick={(e) => {
                e.preventDefault();
                append(certificateDefaultValues);
              }}
            >
              <Plus className="text-gray-500 w-5 h-5" /> Ajouter une autre
              attestation
            </Button>
            <FormField
              control={form.control}
              name="allCertificatesProvided"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      L'ensemble des justificatifs couvrant mon périmètre
                      d'examen a été déposé
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data, true))}
            onPrevious={() => onNextStep(false)}
          />
        </form>
      </Form>
    </div>
  );
};

export default Certificate;
