import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { keyFactory } from "../keyFactory";
import { CompanyInfoDto } from "./dto/company-info.dto";

// company info

const getInfo = async () => {
  return axios
    .get<CompanyInfoDto>(ENDPOINT.company.info)
    .then((res) => res.data);
};

export const useCompanyinfoQuery = (
  props?: Partial<UseQueryOptions<CompanyInfoDto, AxiosError>>
) => {
  return useQuery({
    queryKey: keyFactory.companyInfo(),
    queryFn: getInfo,
    staleTime: Infinity,
    ...props,
  });
};

const changeInfo = async (data: CompanyInfoDto) => {
  return await axios.post<void>(ENDPOINT.company.changeInfo, data);
};

export const useChangeCompanyinfoMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, CompanyInfoDto>
) => {
  return useMutation({
    mutationFn: changeInfo,
    ...props,
  });
};
