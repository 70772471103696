import { SuccessToast } from "@/components/molecules/ToastsTemplates";
import { useAuthStore } from "@/hooks/AuthStore";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Button } from "@atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { Input } from "@atoms/Input";
import { useToast } from "@atoms/Toast/UseToast";
import { zodResolver } from "@hookform/resolvers/zod";
import { connectionSchema, onErrorConnect } from "@molecules/ConnectionForm";
import ErrorDisplay from "@molecules/ErrorDisplay";
import HelloPageLayout from "@templates/hello-page";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useLogInMutation } from "../../../../api/auth/auth";
import { Role } from "../../../../api/enums/Role";
import { ROUTE } from "../../../../constants/routes";

const Login = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const { login } = useAuthStore();

  useRoleBasedRedirect(null);

  const form = useForm<z.infer<typeof connectionSchema>>({
    resolver: zodResolver(connectionSchema),
    defaultValues: {
      email: "",
      key: "",
    },
  });

  const control = form.control;

  const loginMutation = useLogInMutation({
    onSuccess: async ({ data: loginReponse }) => {
      if (loginReponse.role !== Role.ADMIN) {
        setErrorMsg("Ce compte n'a pas le rôle administrateur.");
        return;
      }

      toast({
        action: SuccessToast({
          title: "Connexion réussie",
          description: "Vous pouvez accéder à votre compte",
        }),
        duration: 5000,
      });

      login(form.watch("email"), loginReponse);
    },
    onError: (error) => {
      setErrorMsg(onErrorConnect(error));
    },
  });

  function onSubmit(values: z.infer<typeof connectionSchema>) {
    const { data, error, success } = connectionSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting login :", error);
      return;
    }

    loginMutation.mutate({
      username: data.email,
      key: data.key,
    });
  }

  return (
    <HelloPageLayout maxWidth="max-w-xl" width="w-96">
      <h1>Se connecter</h1>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col space-y-6"
        >
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Adresse email</FormLabel>
                <FormControl>
                  <Input
                    placeholder={"mail@mail.com"}
                    {...field}
                    autoComplete="email"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="key"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Mot de passe</FormLabel>
                <FormControl>
                  <Input placeholder={"********"} {...field} type="password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center flex-col justify-center gap-2 self-stretch">
            <Button
              className="flex font-semibold justify-center items-center self-stretch bg-transparent text-base text-brand-800 focused hover:bg-transparent hover:text-brand-500"
              onClick={(e) => {
                e.preventDefault();
                navigate(ROUTE.forgotten.password.ask);
              }}
              type="button"
            >
              Mot de passe oublié
            </Button>
            <Button
              className="flex px-1 py-2 mt-3 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] font-semibold text-base"
              type="submit"
              disabled={loginMutation.isPending}
            >
              {loginMutation.isPending
                ? "Connection en cours..."
                : "Se connecter"}
            </Button>
            <div className="flex flex-col self-stretch mt-4">
              {errorMsg && <ErrorDisplay msg={errorMsg} />}
            </div>
          </div>
        </form>
      </Form>
    </HelloPageLayout>
  );
};

export default Login;
