import { cn } from "@/lib/utils";
import { CircleAlert, EyeIcon, EyeOffIcon } from "lucide-react";
import * as React from "react";
import { useState } from "react";
import { useFormField } from "./Form";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const { error } = useFormField();
    const isPassword = type === "password";
    const [visible, setVisible] = useState(type !== "password");

    return (
      <div className="relative w-full">
        <input
          type={isPassword ? (visible ? "text" : "password") : type}
          className={cn(
            "flex h-10 w-full shadow-sm border bg-white rounded-lg px-3 py-2 text-sm ring-offset-background  file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 focus-visible:border-brand-300 focus-visible:shadow-brand-300 focus-visible:shadow-[0_0_0_4px_rgba(59,130,246,0.24)]",
            error ? "border-red-500 border" : "border-gray-300",
            className
          )}
          ref={ref}
          {...props}
        />
        {error && !isPassword && (
          <CircleAlert className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500 h-5 w-5" />
        )}
        {isPassword && (
          <button
            className="absolute items-center justify-center right-3 top-1/2 transform -translate-y-1/2 text-gray-500 h-6 w-6 hover:text-gray-700"
            onClick={() => setVisible(!visible)}
            type="button"
          >
            {visible ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeOffIcon className="h-4 w-4" />
            )}
          </button>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
