import { useState } from "react";
import { Button } from "@atoms/Button";
import ExamsPage from "./ExamsPageEnum";
import { Search } from "lucide-react";

const Home = ({
  setActivePage,
}: {
  setActivePage: (page: ExamsPage) => void;
}) => {
  const [exams] = useState([]);

  return (
    <div className="flex flex-col items-start gap-6 self-stretch py-8 px-28">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <h1 className="text-2xl">Mes examens</h1>
          <span className="text-gray-600 text-sm font-">
            M'inscrire et visualiser mes résultats aux examens.
          </span>
        </div>
        {exams.length > 0 && (
          <Button
            className="flex px-3 py-2 mt-3 justify-center gap-1 self-stretch rounded-lg border border-brand-700 bg-brand-600 font-semibold text-sm"
            onClick={() => setActivePage(ExamsPage.Register)}
          >
            S'inscrire à une session d'examen
          </Button>
        )}
      </div>
      {exams.length === 0 && (
        <div className="flex py-8 flex-col gap-4 self-stretch items-center rounded-lg bg-gray-100">
          <Search className="w-6 h-6 text-gray-400" />
          <span className="text-gray-800">
            Vous n'avez pas réalisé d'examen
          </span>
          <Button
            className="flex px-3 py-2 mt-3 justify-center rounded-lg border border-brand-700 bg-brand-600 font-semibold text-sm"
            onClick={() => setActivePage(ExamsPage.Register)}
          >
            S'inscrire à une session d'examen
          </Button>
        </div>
      )}
    </div>
  );
};

export default Home;
