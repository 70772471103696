import {
  useAddCertificateMutation,
  useCandidateCertificatesQuery,
  useCandidateGetUploadLinkMutation,
} from "@/api/candidate/candidate";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { Role } from "@/api/enums/Role";
import { keyFactory } from "@/api/keyFactory";
import { uploadDocument } from "@/api/s3/s3";
import logo from "@/assets/logo.png";
import { Alert, AlertDescription, AlertTitle } from "@/components/atoms/Alert";
import { Button } from "@/components/atoms/Button";
import { Dialog, DialogContent } from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import { toast } from "@/components/atoms/Toast/UseToast";
import DisconnectPrompt from "@/components/molecules/DisconnectPrompt";
import { SuccessToast } from "@/components/molecules/ToastsTemplates";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@atoms/Tabs";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogTitle } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import {
  AlertCircle,
  ArrowDownCircle,
  ArrowRight,
  FileText,
  LucideLogOut,
  User,
  X,
} from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CertificateForm from "../AccountCreation/CertificateForm/CertificateForm";
import {
  certificateDefaultValues,
  certificateSchema,
} from "../AccountCreation/CertificateForm/CertificateFormSchema";
import Delegation from "./Delegation";
import Exams from "./Exams/Exams";
import Profile from "./Profile/Profile";

// accept button props and children
const TopTrigger = (props: React.ComponentProps<typeof TabsTrigger>) => {
  return (
    <TabsTrigger
      className="flex flex-row data-[state=active]:text-brand-700 text-sm text-gray-600 font-medium items-center gap-2.5 rounded-lg data-[state=active]:bg-gray-100 py-2 px-4"
      {...props}
    >
      {props.children}
    </TabsTrigger>
  );
};

interface CertificatesAlertsProps {
  openCertificatePopup: () => void;
}

const CertificatesAlerts = ({
  openCertificatePopup,
}: CertificatesAlertsProps) => {
  const { data: certificates } = useCandidateCertificatesQuery();
  const [warningVisible, setWarningVisible] = useState(true);
  const [errorVisible, setErrorVisible] = useState(true);

  return (
    <>
      {warningVisible &&
        certificates?.some(
          ({ status }) => status === CertificateStatus.PENDING
        ) && (
          <Alert variant="orange">
            <AlertCircle className="h-5 w-5 stroke-white" />
            <AlertTitle className="ml-2">
              Votre certificat étranger n’est pas encore validé par notre équipe
            </AlertTitle>
            <AlertDescription className="font-normal ml-2">
              Vous ne pouvez pas vous inscrire pour le moment en renouvellement
              et en extension pour ce certificat
            </AlertDescription>
            <Button
              variant="none"
              className="absolute top-3 right-3"
              onClick={() => setWarningVisible(false)}
            >
              <X className="w-5 h-5" />
            </Button>
          </Alert>
        )}
      {errorVisible &&
        certificates?.some(
          ({ status }) => status === CertificateStatus.REFUSED
        ) && (
          <Alert variant="red">
            <AlertCircle className="h-5 w-5 stroke-white" />
            <AlertTitle className="ml-2">
              Votre certificat étranger n’a pas été validé. Veuillez déposer un
              nouveau document.
            </AlertTitle>
            <AlertDescription className="font-normal ml-2">
              Vous ne pouvez pas vous inscrire pour le moment en renouvellement
              et en extension pour ce certificat
            </AlertDescription>
            <Button
              variant="none"
              className="absolute top-3 right-3"
              onClick={() => setErrorVisible(false)}
            >
              <X className="w-5 h-5" />
            </Button>
            <Button
              className="absolute top-4 right-20 bg-white border rounded-lg text-[#DC2626] gap-2 hover:text-white hover:bg-[#DC2626] hover:border-white"
              onClick={openCertificatePopup}
            >
              Déposer mon certificat étranger
              <ArrowRight className="w-5 h-5" />
            </Button>
          </Alert>
        )}
    </>
  );
};

interface AddCertificateDialogProps {
  isOpen: boolean;
  close: () => void;
}

const AddCertificateDialog = ({ close, isOpen }: AddCertificateDialogProps) => {
  const uploadLinkMutation = useCandidateGetUploadLinkMutation();
  const addCertificateMutation = useAddCertificateMutation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof certificateSchema>>({
    resolver: zodResolver(certificateSchema),
    defaultValues: certificateDefaultValues,
  });

  const onSubmit = (values: z.infer<typeof certificateSchema>) => {
    const { data, error, success } = certificateSchema.safeParse(values);

    if (!success || !data) {
      console.error("can't parse form, aborting registration :", error);
      return;
    }

    setIsLoading(true);
    const { file, ...dto } = data;

    uploadLinkMutation.mutate(
      {
        fileName: file.name,
      },
      {
        onError: () => setIsLoading(false),
        onSuccess: async ({ putObjectURL, ...responseFile }) => {
          await uploadDocument(putObjectURL, file);
          addCertificateMutation.mutate(
            {
              ...dto,
              file: responseFile,
            },
            {
              onSuccess: () => {
                setIsLoading(false);
                toast({
                  action: SuccessToast({
                    title: "Certificat ajouté",
                    description:
                      'Vous pouvez le consulter dans la rubrique "Mon profil" > "Mes certificats"',
                  }),
                  duration: 5000,
                });

                queryClient.invalidateQueries({
                  queryKey: keyFactory.candidateCertificates(),
                });
                close();
                form.reset();
              },
              onError: () => setIsLoading(false),
            }
          );
        },
      }
    );
  };

  return (
    <Dialog modal={true} open={isOpen}>
      <DialogContent showCloseButton={false} className="max-w-3xl">
        <DialogTitle className="text-gray-950 text-normal font-semibold text-xl">
          Soumettre un certificat
        </DialogTitle>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-8"
          >
            <Button
              variant="none"
              className="absolute top-3 right-3"
              onClick={close}
            >
              <X className="w-5 h-5" />
            </Button>
            <CertificateForm />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Création du certificat..." : "Soumettre"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

enum DashboardPage {
  Exams = "Exams",
  Delegation = "Delegation",
  Profile = "Profile",
}

const Dashboard = () => {
  const [disconnectIsOpen, setDisconnectIsOpen] = useState(false);
  const [addCertificatesDisplayed, setAddCertificatesDisplayed] =
    useState(false);

  useRoleBasedRedirect(Role.CANDIDATE);

  return (
    <>
      <AddCertificateDialog
        isOpen={addCertificatesDisplayed}
        close={() => setAddCertificatesDisplayed(false)}
      />
      <CertificatesAlerts
        openCertificatePopup={() => setAddCertificatesDisplayed(true)}
      />
      <DisconnectPrompt
        isOpen={disconnectIsOpen}
        close={() => setDisconnectIsOpen(false)}
      />
      <Tabs defaultValue={DashboardPage.Exams}>
        <div className="flex py-4 px-20 justify-between items-center self-stretch bg-white">
          <div className="flex flex-row gap-7">
            <img
              className="w-11 h-11"
              src={logo}
              alt="Description of the image"
            />
            <TabsList className="bg-transparent">
              <TopTrigger value={DashboardPage.Exams}>
                <FileText className="w-4 h-4" />
                Mes examens
              </TopTrigger>
              <TopTrigger value={DashboardPage.Delegation}>
                <ArrowDownCircle className="w-4 h-4" />
                Ma délégation
              </TopTrigger>
              <TopTrigger value={DashboardPage.Profile}>
                <User className="w-4 h-4" />
                Mon profil
              </TopTrigger>
            </TabsList>
          </div>
          <div className="flex items-center gap-6">
            <div className="text-gray-600">
              <span className="text-sm font-medium">Demande d'aide : </span>
              <span className="font-bold underline">contact@cifmd.fr</span>
            </div>
            <Button
              className="flex flex-row py-2 px-3 justify-center items-center gap-1 rounded-lg border border-gray-300 bg-white text-gray-800 hover:bg-gray-10"
              onClick={() => setDisconnectIsOpen(true)}
            >
              <LucideLogOut />
              <span className="font-semibold leading-6">Se déconnecter</span>
            </Button>
          </div>
        </div>
        <TabsContent value={DashboardPage.Exams}>
          <Exams />
        </TabsContent>
        <TabsContent value={DashboardPage.Delegation}>
          <Delegation />
        </TabsContent>
        <TabsContent value={DashboardPage.Profile} className="relative">
          <Profile
            openCertificatePopup={() => setAddCertificatesDisplayed(true)}
          />
        </TabsContent>
      </Tabs>
    </>
  );
};

export default Dashboard;
