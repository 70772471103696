import HelloPageLayout from "@templates/hello-page";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@atoms/Tabs";
import CandidateCreateAccount from "./CandidateCreateAccount";
import Connect from "./Connect";

const Home = () => {
  useRoleBasedRedirect(null);

  return (
    <HelloPageLayout maxWidth="max-w-xl">
      <Tabs defaultValue="connect">
        <TabsList className="flex px-0 py-0.5">
          <TabsTrigger
            value="connect"
            className="flex m-0.5 w-full h-full items-center justify-center"
          >
            Déjà un compte
          </TabsTrigger>
          <TabsTrigger
            value="register"
            className="flex m-0.5 w-full h-full items-center justify-center"
          >
            Créer un compte
          </TabsTrigger>
        </TabsList>
        <TabsContent value="connect">
          <Connect />
        </TabsContent>
        <TabsContent value="register">
          <CandidateCreateAccount />
        </TabsContent>
      </Tabs>
    </HelloPageLayout>
  );
};

export default Home;
