import { Sex } from "@/api/enums/Sex";
import { useFormContext } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel } from "../atoms/Form";
import { RadioGroup, RadioGroupItem } from "../atoms/RadioGroup";

interface SexRadioProps {
  name: string;
  disabled?: boolean;
}

const SexRadio = ({ name, disabled = false }: SexRadioProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormControl>
            <RadioGroup
              value={field.value}
              onValueChange={field.onChange}
              className="flex flex-row gap-4"
            >
              {[
                { value: Sex.MALE, label: "Monsieur" },
                { value: Sex.FEMALE, label: "Madame" },
              ]
                .filter(({ value }) => !disabled || value === field.value)
                .map(({ label, value }) => (
                  <FormItem
                    className="flex items-center self-stretch pr-14"
                    key={value}
                  >
                    <FormControl>
                      <div className="flex items-center">
                        <RadioGroupItem
                          id={value}
                          value={value}
                          className="flex items-center justify-center"
                          disabled={disabled}
                        />
                        <FormLabel htmlFor={value}>{label}</FormLabel>
                      </div>
                    </FormControl>
                  </FormItem>
                ))}
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );
};

export default SexRadio;
