import {
  CertificateStatus,
  CertificateStatusUtil,
} from "@/api/enums/CertificateStatus";
import { useCandidateCertificatesQuery } from "@/api/candidate/candidate";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { PerimeterUtil } from "@/components/molecules/PerimeterForm/PerimeterFormSchema";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { ArrowDown } from "lucide-react";
import { Link } from "react-router-dom";

interface CertificatesProps {
  openCertificatePopup: () => void;
}

const Certificates = ({ openCertificatePopup }: CertificatesProps) => {
  const { isLoading, data, isError } = useCandidateCertificatesQuery();

  if (isLoading || isError)
    return (
      <LoadingSpinner
        isLoading={isLoading}
        loadingMessage="Chargement de vos certificats"
        isError={isError}
        errorMessage="Erreur lors du chargement de vos certificats"
      />
    );

  return (
    <>
      <Button
        className="absolute top-24 right-28"
        onClick={openCertificatePopup}
      >
        Soumettre un certificat
      </Button>
      <Table className="text-gray-500 text-sm font-normal leading-6">
        <TableHeader className="gap-3 items-center border-gray-200 border bg-gray-50">
          <TableRow>
            {[
              "Date de dêpot",
              "Statut",
              "Pays",
              "n° certificat",
              "Fin de validité",
              "Périmètre",
            ].map((text, i) => (
              <TableHead key={i}>
                <div className="flex flex-row items-center gap-3">
                  {text}
                  <ArrowDown className="w-4 h-4" />
                </div>
              </TableHead>
            ))}
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.map((certificate, i) => (
            <TableRow
              className={cn({
                "bg-white": i % 2 === 0,
                "bg-gray-50": i % 2 !== 0,
              })}
              key={i}
            >
              <TableCell className="text-gray-500 ">
                {format(certificate.expirationDate, "dd/MM/yyyy")}
              </TableCell>
              <TableCell className="w-full">
                <Badge
                  variant={
                    certificate.status === CertificateStatus.PENDING
                      ? "orange"
                      : certificate.status === CertificateStatus.REFUSED
                      ? "red"
                      : "green"
                  }
                >
                  {CertificateStatusUtil.toString(
                    certificate.status as CertificateStatus
                  )}
                </Badge>
              </TableCell>
              <TableCell>{certificate.country}</TableCell>
              <TableCell>{certificate.number}</TableCell>
              <TableCell>
                {format(certificate.expirationDate, "dd/MM/yyyy")}
              </TableCell>
              <TableCell>
                <Badge variant="white">
                  {PerimeterUtil.perimeterToCode(certificate.perimeter)}
                </Badge>
              </TableCell>
              <TableCell>
                <Button variant="blueLink">
                  <Link
                    to={certificate.file.signedAccessKey as string}
                    target="_blank"
                    download
                  >
                    Télécharger
                  </Link>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Certificates;
