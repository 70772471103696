import { useChangeEmailMutation } from "@/api/auth/auth";
import {
  useChangeCompanyinfoMutation,
  useCompanyinfoQuery,
} from "@/api/company/company";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { useToast } from "@/components/atoms/Toast/UseToast";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  mandatoryAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import DisconnectPrompt from "@/components/molecules/DisconnectPrompt";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { PhoneInput } from "@/components/molecules/PhoneInput";
import { SuccessToast } from "@/components/molecules/ToastsTemplates";
import { ROUTE } from "@/constants/routes";
import {
  email,
  mandatoryString,
  optionalPhone,
  optionnalString,
} from "@/constants/zodTypes";
import { useAuthStore } from "@/hooks/AuthStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import { Edit } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

const companyModificationSchema = z.object({
  address: mandatoryAddressSchema,
  personName: optionnalString(),
  personSurname: optionnalString(),
  phoneNumber: optionalPhone(),
  email: email(),
  name: mandatoryString(),
});

interface InfoFormProps {
  disabled: boolean;
}

const InfoForm = ({ disabled }: InfoFormProps) => {
  const { control } = useFormContext();
  const [disconnectsOpen, setDisconnectsOpen] = useState(false);

  return (
    <div className="flex flex-col w-full gap-2">
      <DisconnectPrompt
        isOpen={disconnectsOpen}
        close={() => setDisconnectsOpen(false)}
        redirectionPage={ROUTE.forgotten.password.ask}
      />
      <h2>Informations personnelles</h2>
      <div className="flex flex-row gap-6">
        <FormField
          control={control}
          name="personName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Nom</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Mobile</FormLabel>
              <FormControl>
                <PhoneInput
                  defaultCountry="FR"
                  disabled={disabled}
                  placeholder={"01 XX XX XX XX"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex flex-row gap-6">
        <FormField
          control={control}
          name="personSurname"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Prénom</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Adresse email *</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex flex-row gap-6">
        <FormField
          control={control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Nom de l'entreprise *</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!disabled && (
          <div className="flex w-full items-end">
            <Button
              type="button"
              variant="outlineBlue"
              onClick={() => setDisconnectsOpen(true)}
              className="w-full"
            >
              <Edit className="w-4 h-4" />
              Modifier mon mot de passe
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Information = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const [errorMsg, setErrorMsg] = useState("");
  const [infoIsModifiable, setInfoIsModifiable] = useState(false);
  const [addressIsModifiable, setAddressIsModifiable] = useState(false);
  const { session, setNewEmail } = useAuthStore();

  //////////////////////////
  // api calls
  //////////////////////////

  const getInfoQuery = useCompanyinfoQuery();

  const changeInfoMutation = useChangeCompanyinfoMutation({
    onSuccess: async () => {
      setErrorMsg("");

      toast({
        action: SuccessToast({
          title: "",
          description: "Vos informations ont été modifiées avec succès.",
        }),
        duration: 3000,
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.companyInfo(),
      });

      setAddressIsModifiable(false);
      setInfoIsModifiable(false);
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la modification de vos informations."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la modification de vos informations."
          );
          return;
      }
    },
  });

  const changeUserEmailMutation = useChangeEmailMutation({
    onSuccess: () => {
      const { email, ...pi } = watch();
      changeInfoMutation.mutate(pi);
    },
  });

  //////////////////////////
  // form
  //////////////////////////

  const form = useForm<z.infer<typeof companyModificationSchema>>({
    resolver: zodResolver(companyModificationSchema),
    defaultValues: {
      phoneNumber: "",
      email: "",
      address: addressDefaultValues,
      personName: "",
      personSurname: "",
    },
  });

  // set form values when data is fetched
  const { data: companyInfo } = getInfoQuery;
  const { reset, watch } = form;

  const resetForm = useCallback(() => {
    if (!companyInfo) return;
    reset({
      ...watch(),
      ...companyInfo,
      email: session?.email as string,
    });
  }, [companyInfo, session, reset, watch]);

  useEffect(resetForm, [resetForm]);

  const onSubmit = (values: z.infer<typeof companyModificationSchema>) => {
    const { data, error, success } =
      companyModificationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    const { email: newEmail, ...newCompanyInfo } = data;

    if (newEmail !== session?.email) {
      changeUserEmailMutation.mutate({ newEmail });
      setNewEmail(newEmail);
    } else {
      changeInfoMutation.mutate(newCompanyInfo);
    }
  };

  //////////////////////////
  // component
  //////////////////////////

  // wait for query loading
  if (!getInfoQuery.data)
    return (
      <LoadingSpinner
        isLoading={getInfoQuery.isLoading}
        isError={getInfoQuery.isError}
        loadingMessage="Chargement des informations entreprise..."
        errorMessage="Erreur lors du chargement des informations entreprise."
      />
    );

  return (
    <>
      <div className="flex flex-col w-full border-b border-b-gray-200">
        <div className="py-3 px-6 bg-white w-full">
          <h1>Informations entreprise</h1>
        </div>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-8 p-8"
        >
          <ModifiableForm
            setModifiable={(b: boolean) => {
              if (b && addressIsModifiable) {
                setAddressIsModifiable(false);
                resetForm();
              }
              setInfoIsModifiable(b);
            }}
            isModifiable={infoIsModifiable}
            onCancel={resetForm}
            modifyText="Modifier les informations"
            saveText="Sauvegarder les informations"
          >
            <InfoForm disabled={!infoIsModifiable} />
          </ModifiableForm>
          <ModifiableForm
            setModifiable={(b: boolean) => {
              if (b && infoIsModifiable) {
                setInfoIsModifiable(false);
                resetForm();
              }
              setAddressIsModifiable(b);
            }}
            isModifiable={addressIsModifiable}
            onCancel={resetForm}
            modifyText="Modifier l'adresse de facturation"
            saveText="Sauvegarder l'adresse de facturation"
          >
            <AddressForm
              fieldName="address"
              disabledFields={
                addressIsModifiable ? [] : Object.values(AddressFields)
              }
              title="Adresse facturation"
              isMandatory={true}
            />
          </ModifiableForm>
          {errorMsg && <ErrorDisplay msg={errorMsg} />}
        </form>
      </Form>
    </>
  );
};

export default Information;
