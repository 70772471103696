import logo from "@/assets/logo.png";
import { ChevronsLeft, ChevronsRight, LogOut } from "lucide-react";
import { ReactNode, useState } from "react";
import { Button } from "react-day-picker";
import DisconnectPrompt from "./DisconnectPrompt";

interface LeftBarExpandedProps {
  setCollapsed: (expanded: boolean) => void;
  children?: ReactNode;
  collapsable?: boolean;
}

const LeftBarExpanded = ({
  setCollapsed,
  children,
  collapsable = true,
}: LeftBarExpandedProps) => {
  const [disconnectIsOpen, setDisconnectIsOpen] = useState(false);

  return (
    <>
      <DisconnectPrompt
        isOpen={disconnectIsOpen}
        close={() => setDisconnectIsOpen(false)}
      />
      <div className="flex w-72 flex-col flex-start justify-between h-screen flex-shrink-0 bg-brand-25 border-r border-r-gray-200 gap-8 pt-8">
        <div className="flex flex-col">
          <div className="flex flex-row pl-8 pr-4 justify-between items-center self-stretch">
            <img
              className="w-16 h-16 as"
              src={logo}
              alt="Description of the image"
            />
            {collapsable && (
              <Button onClick={() => setCollapsed(true)}>
                <ChevronsLeft />
              </Button>
            )}
          </div>
        </div>
        {children}
        <div className="flex flex-col">
          <div className="flex flex-col px-4 py-6 self-stretch">
            <Button
              className="flex flex-row gap-3 items-center self-stretch p-2"
              onClick={() => setDisconnectIsOpen(true)}
            >
              <LogOut />
              Se déconnecter
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

interface LeftBarCollapsedProps {
  setCollapsed: (expanded: boolean) => void;
  children?: ReactNode;
}

const LeftBarCollapsed = ({
  setCollapsed,
  children,
}: LeftBarCollapsedProps) => {
  return (
    <div className="flex flex-col w-16 flex-start h-screen flex-shrink-0 bg-brand-25 border-r border-r-gray-200 gap-8 pt-8">
      <Button
        className="absolute top-2 left-10"
        onClick={() => setCollapsed(false)}
      >
        <ChevronsRight className="w-4 h-4" />
      </Button>
      <div className="flex justify-center items-center self-stretch">
        <img className="w-8 h-8 as" src={logo} alt="logo" />
      </div>
      {children}
    </div>
  );
};

interface SideBarProps {
  collapsedByDefault?: boolean;
  collapsable?: boolean;
  expandedChildren?: ReactNode;
  collapsedChildren?: ReactNode;
}

const SideBar = ({
  collapsedByDefault = false,
  collapsable,
  collapsedChildren,
  expandedChildren,
}: SideBarProps) => {
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  return collapsed ? (
    <LeftBarCollapsed
      setCollapsed={setCollapsed}
      children={collapsedChildren}
    />
  ) : (
    <LeftBarExpanded
      setCollapsed={setCollapsed}
      children={expandedChildren}
      collapsable={collapsable}
    />
  );
};

export default SideBar;
