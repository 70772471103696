import SexRadio from "@/components/molecules/SexRadio";
import { countries } from "@/constants/countries";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { Input } from "@atoms/Input";
import { ComboboxField } from "@molecules/Combobox";
import DatePicker from "@molecules/DatePicker";
import { PhoneInput } from "@molecules/PhoneInput";
import { useFormContext } from "react-hook-form";
import { PersonnalInfoFields } from "./PersonnalInfoFields";

const MandatoryText = (text: string) => {
  return (
    <>
      {text} <span className="text-brand-600">*</span>
    </>
  );
};

const formatOptionList = (options: string[]) => {
  const l = options.map(
    (option) => option.charAt(0).toUpperCase() + option.slice(1)
  );
  l.sort();
  return l;
};

interface PersonnalInformationFormProps {
  disabledFields?: PersonnalInfoFields[];
  hiddenFields?: PersonnalInfoFields[];
}

const PersonnalInformationForm = ({
  disabledFields = [],
  hiddenFields = [],
}: PersonnalInformationFormProps) => {
  const { control } = useFormContext();

  const rowClasses = "flex flex-row gap-2 w-full mt-1";

  return (
    <div className="flex flex-col w-full">
      <h2>Informations personnelles</h2>
      <div className="flex flex-col gap-1">
        {!hiddenFields.includes(PersonnalInfoFields.Sex) && (
          <div className={rowClasses + " py-2"}>
            <SexRadio
              name="personnalInfo.sex"
              disabled={disabledFields.includes(PersonnalInfoFields.Sex)}
            />
          </div>
        )}
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoFields.LastName) && (
            <FormField
              control={control}
              name="personnalInfo.lastName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{MandatoryText("Nom")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"-"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.LastName
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoFields.FirstName) && (
            <FormField
              control={control}
              name="personnalInfo.firstName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{MandatoryText("Prénom")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"-"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.FirstName
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoFields.Birthdate) && (
            <FormField
              control={control}
              name="personnalInfo.birthdate"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{MandatoryText("Date de naissance")}</FormLabel>
                  <DatePicker
                    value={field.value}
                    onChange={field.onChange}
                    restrictToToday={true}
                    disabled={disabledFields.includes(
                      PersonnalInfoFields.Birthdate
                    )}
                    disabledDates={(date) =>
                      date >
                      new Date(
                        new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000
                      )
                    }
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoFields.BirthCity) && (
            <FormField
              control={control}
              name="personnalInfo.birthCity"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{MandatoryText("Ville de naissance")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"Constantine"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.BirthCity
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoFields.BirthCountry) && (
            <FormField
              control={control}
              name="personnalInfo.birthCountry"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Pays de naissance</FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={formatOptionList(
                        countries.map((country) => country.libelle)
                      ).map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      placeholder={"-"}
                      value={field.value}
                      onChange={field.onChange}
                      optionnal={true}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.BirthCountry
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoFields.Nationality) && (
            <FormField
              control={control}
              name="personnalInfo.nationality"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>{MandatoryText("Nationalité")}</FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={formatOptionList(
                        countries.map((country) => country.nationalite)
                      ).map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      placeholder={"-"}
                      value={field.value}
                      onChange={field.onChange}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.Nationality
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoFields.Email) && (
            <FormField
              control={control}
              name="credentials.email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Adresse email *</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"mail@mail.com"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.Email
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoFields.Email) && (
            <FormField
              control={control}
              name="credentials.emailConfirmation"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Confirmation adresse email *</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"mail@mail.com"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.Email
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoFields.Password) && (
            <FormField
              control={control}
              name="credentials.key"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mot de passe *</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="password"
                      type="password"
                      placeholder={"*****"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.Password
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoFields.Password) && (
            <FormField
              control={control}
              name="credentials.keyConfirmation"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Confirmation mot de passe *</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="password"
                      type="password"
                      placeholder={"*****"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.Password
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoFields.HomePhone) && (
            <FormField
              control={control}
              name="personnalInfo.homePhone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Téléphone fixe</FormLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={"01 XX XX XX XX"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.HomePhone
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoFields.MobilePhone) && (
            <FormField
              control={control}
              name="personnalInfo.mobilePhone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mobile</FormLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={"06 XX XX XX XX"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoFields.MobilePhone
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonnalInformationForm;
