import { emptyfile } from "@/constants/zodTypes";
import { cn } from "@/lib/utils";
import { FormField, FormItem, FormLabel, FormMessage } from "@atoms/Form";
import { CloudUpload, Trash2 } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useFormContext } from "react-hook-form";

interface DropzoneProps {
  children?: ReactNode;
  fieldName: string;
  canBeRemoved?: boolean;
  label?: ReactNode;
}

const DropzoneMolecule = ({
  fieldName,
  children,
  canBeRemoved = false,
  label,
}: DropzoneProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileUrl]);

  // Watch for changes in the field's value
  const fieldValue = watch(fieldName);

  useEffect(() => {
    // Generate the file URL if a file exists
    if (fieldValue instanceof File) {
      const url = URL.createObjectURL(fieldValue);
      setFileUrl(url);

      // Clean up the URL when the component unmounts or the file changes
      return () => URL.revokeObjectURL(url);
    } else {
      setFileUrl(null);
    }
  }, [fieldValue]);

  const fieldError = errors[fieldName];

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            {label !== undefined ? label : "Veuillez déposer votre certificat"}
          </FormLabel>
          <Dropzone
            onDrop={(acceptedFiles) => {
              const file = acceptedFiles.length ? acceptedFiles[0] : null;
              field.onChange(file);
              if (file) {
                setFileUrl(URL.createObjectURL(file));
              } else {
                setFileUrl(null);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={cn(
                  "flex px-6 py-4 flex-col items-center gap-1 self-stretch rounded-xl border border-gray-200 bg-white",
                  fieldError && "border-red-500 focus-within:border-red-500"
                )}
              >
                <input
                  {...getInputProps()}
                  onClick={(e) => e.preventDefault()}
                />
                <div className="p-2 items-center justify-center flex rounded-lg border border-gray-200 bg-white shadow-sm">
                  <CloudUpload className="w-5 h-5" />
                </div>
                {children}
                <div className="flex flex-row gap-4">
                  {field.value ? (
                    <a
                      href={fileUrl || "#"}
                      download={field.value.name}
                      className="font-bold"
                      onClick={(e) => {
                        if (!fileUrl) e.preventDefault(); // Prevents navigation if no file
                        e.stopPropagation();
                      }}
                    >
                      {field.value.name}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                  {canBeRemoved && (field.value as File)?.size !== 0 && (
                    <button
                      className="text-red-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        field.onChange(emptyfile());
                        setFileUrl(null); // Clear the file URL
                      }}
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default DropzoneMolecule;
