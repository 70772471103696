enum CertificateStatus {
  PENDING = "PENDING",
  REFUSED = "REFUSED",
  VALIDATED = "VALIDATED",
}

class CertificateStatusUtil {
  public static toString = (status: CertificateStatus): string => {
    switch (status) {
      case CertificateStatus.PENDING:
        return "En cours de validation";

      case CertificateStatus.REFUSED:
        return "Refusé";

      case CertificateStatus.VALIDATED:
        return "Validé";
    }
  };
}

export { CertificateStatus, CertificateStatusUtil };
