import { RegisterCandidateDto } from "@/api/auth/dto/register-candidate.dto";
import { uploadDocument } from "@/api/s3/s3";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Button } from "@atoms/Button";
import { Form } from "@atoms/Form";
import { useToast } from "@atoms/Toast/UseToast";
import { zodResolver } from "@hookform/resolvers/zod";
import AddressForm from "@molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionnalAddressSchema,
} from "@molecules/AddressForm/AddressFormSchema";
import ErrorDisplay from "@molecules/ErrorDisplay";
import { SuccessToast } from "@molecules/ToastsTemplates";
import HelloPageLayout from "@templates/hello-page";
import { AxiosError, HttpStatusCode } from "axios";
import { LucideChevronLeft, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  useAskUploadLinksMutation,
  useRegisterUserMutation,
} from "../../../../api/auth/auth";
import { ROUTE } from "../../../../constants/routes";
import CertificateForm from "./CertificateForm/CertificateForm";
import {
  certificateDefaultValues,
  certificateSchema,
} from "./CertificateForm/CertificateFormSchema";
import PersonnalInformationForm from "../../../molecules/PersonnalInfoForm/PersonnalInfoForm";
import {
  credentialsDefaultValues,
  credentialsSchema,
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "../../../molecules/PersonnalInfoForm/PersonnalInfoFormSchema";

const accountCreationSchema = z.object({
  credentials: credentialsSchema,
  address: optionnalAddressSchema,
  personnalInfo: personnalInfoSchema,
  foreignCertificates: z.array(certificateSchema),
});

const TopText = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-1 items-start">
      <Button
        // TODO bad style
        className="p-0 m-0 bg-transparent text-gray-800 gap-1.5 hover:text-brand-700 hover:bg-transparent text-xs"
        onClick={() => navigate(-1)}
      >
        <LucideChevronLeft />
        Retour à la page précédente
      </Button>
      <h1 className="mt-1.5">Création du compte</h1>
      <p className="mt-1">
        Complétez les différents champs pour la création de votre compte.
        <span className="text-brand-600 font-bold">
          {" "}
          Les champs marqués d'un astérisque *{" "}
        </span>
        figureront sur votre certificat : ils doivent être soigneusement
        vérifiés et correctement renseignés.
      </p>
    </div>
  );
};

const AccountCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasCertificate = location.state?.hasCertificate ?? (false as boolean);
  const { toast } = useToast();
  const [errorMsg, setErrorMsg] = useState("");
  const [formData, setFormData] = useState<z.infer<
    typeof accountCreationSchema
  > | null>(null);

  useRoleBasedRedirect(null);

  // need to know if candidate has certificate(s)
  useEffect(() => {
    if (!location.state)
      navigate(ROUTE.connectionHome, { state: { hasCertificate: false } });
  });

  const formDataToDto = (
    {
      credentials,
      personnalInfo: pi,
      address,
      foreignCertificates,
    }: z.infer<typeof accountCreationSchema>,
    certificatesS3Keys: string[] = []
  ): RegisterCandidateDto => {
    return {
      userInfo: {
        email: credentials.email,
        key: credentials.key,
      },
      personnalInfo: pi,
      ...(address.country && { address }),
      foreignCertificates: foreignCertificates.map(({ file, ...props }, i) => ({
        ...props,
        file: {
          fileKey: certificatesS3Keys[i],
          fileName: file.name,
        },
      })),
    };
  };

  const uploadCertificates = async (putLinks: string[]) => {
    if (!formData) {
      console.error("formData is null, can't upload certificates");
      return;
    }

    for (let i = 0; i < putLinks.length; i++) {
      await uploadDocument(putLinks[i], formData.foreignCertificates[i].file);
    }
  };

  const handleErrorCode = (error: AxiosError) => {
    switch (error.status) {
      case HttpStatusCode.Conflict:
        setErrorMsg("Cet email est déjà utilisé.");
        return;

      case HttpStatusCode.InternalServerError:
        setErrorMsg(
          "Le serveur a rencontré une erreur lors de la création de votre compte."
        );
        return;

      case HttpStatusCode.BadRequest:
        if (
          error.response?.data !== undefined &&
          (error.response?.data as { message: string[] }).message[0].endsWith(
            "empty"
          )
        ) {
          setErrorMsg(
            "Les périmètres doivent contenir au moins un mode de transport et une catégorie de marchandises."
          );
        } else {
          setErrorMsg(
            "Une erreur est survenue lors de la création de votre compte."
          );
        }
        break;

      default:
        setErrorMsg(
          "Une erreur est survenue lors de la création de votre compte."
        );
        return;
    }
  };

  const askUploadLinksMutation = useAskUploadLinksMutation({
    onSuccess: async (response) => {
      // no upload links
      if (!response) {
        if (
          formData &&
          formData.foreignCertificates &&
          formData.foreignCertificates.length > 0
        ) {
          console.error(
            `there were ${formData.foreignCertificates.length} certificates to upload but server didn't responded with upload links`
          );
        }

        return;
      }

      // upload links are available
      await uploadCertificates(
        response.map(({ putObjectURL }) => putObjectURL)
      );
      if (formData != null)
        registerUserMutation.mutate(
          formDataToDto(
            formData,
            response.map(({ fileKey }) => fileKey)
          )
        );
      else throw "data is null";
    },
    onError: (error) => {
      setFormData(null);
      handleErrorCode(error);
    },
  });

  const registerUserMutation = useRegisterUserMutation({
    onSuccess: () => {
      navigate(ROUTE.connectionHome);
      toast({
        action: SuccessToast({
          title: "Votre compte a bien été créé",
          description: "Vous pouvez maintenant y accéder",
        }),
        duration: 3000,
      });
    },
    onError: (error) => {
      setFormData(null);
      handleErrorCode(error);
    },
  });

  const form = useForm<z.infer<typeof accountCreationSchema>>({
    resolver: zodResolver(accountCreationSchema),
    defaultValues: {
      credentials: credentialsDefaultValues,
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
      foreignCertificates: hasCertificate ? [certificateDefaultValues] : [],
    },
  });

  const onSubmit = (values: z.infer<typeof accountCreationSchema>) => {
    const { data, error, success } = accountCreationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting registration :", error);
      return;
    }

    setFormData(data);

    if (data.foreignCertificates.length === 0) {
      registerUserMutation.mutate(formDataToDto(data, []));
    } else {
      askUploadLinksMutation.mutate({
        askUploads: data.foreignCertificates.map((certif) => {
          return { fileName: certif.file.name };
        }),
      });
    }
  };

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "foreignCertificates",
  });

  return (
    <HelloPageLayout maxWidth="max-w-3xl">
      <TopText />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col space-y-8"
        >
          <PersonnalInformationForm />
          {hasCertificate && (
            <div className="flex flex-col w-full gap-2">
              <h2>Certificat(s) étranger</h2>
              {fields.map((field, index) => (
                <CertificateForm
                  key={field.id}
                  remove={index > 0 ? () => remove(index) : null}
                  fieldName={`foreignCertificates.${index}`}
                />
              ))}
              <Button
                variant={"white"}
                className="w-full text-gray-800 font-semibold text-sm gap-2"
                onClick={(e) => {
                  e.preventDefault();
                  append(certificateDefaultValues);
                }}
              >
                <Plus className="text-gray-500 w-5 h-5" /> Ajouter un autre
                certificat
              </Button>
            </div>
          )}
          <AddressForm fieldName="address" />
          <Button
            className="flex w-full font-semibold bg-brand-600 text-white"
            type="submit"
            disabled={
              askUploadLinksMutation.isPending || registerUserMutation.isPending
            }
          >
            {askUploadLinksMutation.isPending || registerUserMutation.isPending
              ? "Création de votre compte..."
              : "Créer mon compte"}
          </Button>
          {errorMsg && <ErrorDisplay msg={errorMsg} />}
        </form>
      </Form>
    </HelloPageLayout>
  );
};

export default AccountCreation;
