import { LoginResponse } from "@/api/auth/dto/login.dto";
import axios from "axios";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Role } from "../api/enums/Role";

interface Session {
  email: string;
  role: Role;
  token: string;
  tokenExpirationTime: number;
  refreshTokenExpirationTime: number;
}

interface AuthState {
  session?: Session;
}

interface AuthActions {
  logout: () => void;
  login: (email: string, loginResponse: LoginResponse) => void;
  setNewEmail: (newEmail: string) => void;
}

const setAxiosAuthHeader = (token: string) => {
  axios.defaults.headers.common.Authorization = "Bearer " + token;
};

export const useAuthStore = create(
  persist<AuthState & AuthActions>(
    (set) => ({
      session: undefined,
      logout: () => {
        set(() => ({
          session: undefined,
        }));
      },
      login: (
        email,
        { token, role, tokenExpirationTime, refreshTokenExpirationTime }
      ) => {
        setAxiosAuthHeader(token);
        set(() => ({
          session: {
            role,
            token,
            email,
            tokenExpirationTime: new Date(tokenExpirationTime).getTime(),
            refreshTokenExpirationTime: new Date(
              refreshTokenExpirationTime
            ).getTime(),
          },
        }));
      },
      setNewEmail: (newEmail: string) =>
        set(({ session }) => ({
          session: session ? { ...session, email: newEmail } : undefined,
        })),
    }),
    {
      // storing zustand in local storage for persistence
      name: "auth-storage",
      // set auth header when zustand reloads
      onRehydrateStorage: () => (state) => {
        if (state?.session?.token) setAxiosAuthHeader(state.session.token);
      },
    }
  )
);
